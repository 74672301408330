import {
  formatDecimal,
  formatOnePrecision,
  formatPercent,
  formatPrice,
  formatPriceEuro,
  formatThousendSeparator,
  formatThreePrecision,
} from '@/filter/numeral';

export default {
  methods: {
    formatThousendSeparator(value) {
      return formatThousendSeparator(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    formatPriceEuro(value) {
      return formatPriceEuro(value);
    },
    formatOnePrecision(value) {
      return formatOnePrecision(value);
    },
    formatThreePrecision(value) {
      return formatThreePrecision(value);
    },
    formatDecimal(value) {
      return formatDecimal(value);
    },
    formatPercent(value) {
      return formatPercent(value);
    },
  },
};

<template>
  <div>
    <navbar :title="title" :zentrale-to="{ name: 'eingangsrechnungen' }" page="eingangsrechnung" />
    <b-container fluid="md">
      <b-row>
        <b-col xl="4">
          <bx-box>
            <bx-box variant="ice">
              <b-row>
                <b-col cols="6">
                  <b-button type="default" class="w-100" @click="$router.back()">
                    <i class="ifr if-arrow-left mr-2" />
                    <span>Zurück</span>
                  </b-button>
                </b-col>
                <div class="w-100" />
                <b-col cols="6">
                  <b-form-group label="Vorgangsnummer">
                    <b-form-input v-model="vorgangsnummer" disabled size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Rechnungsnummer">
                    <b-form-input v-model="rechnungsnummer" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Rechnungsdatum" label-class="hyphens-auto">
                    <vue-date-time-picker id="datum-rechnung" v-model="datum_rechnung" size="sm" format="DD.MM.YYYY" earlier-translation="Früher" later-translation="Später" onlyDate autoClose
                      noClearButton formatted="DD.MM.YYYY" button-now-translation="Heute" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Datum Skonto">
                    <vue-date-time-picker id="datum-skonto" v-model="datum_skonto" size="sm" format="DD.MM.YYYY" earlier-translation="Früher" later-translation="Später" onlyDate autoClose noClearButton
                      formatted="DD.MM.YYYY" button-now-translation="Heute" :state="input_state('datum_skonto')" invalid-feedback="Bitte geben Sie ein Skonto-Datum ein" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Datum Fälligkeit">
                    <vue-date-time-picker id="datum-fälligkeit" v-model="datum_faelligkeit" size="sm" format="DD.MM.YYYY" earlier-translation="Früher" later-translation="Später" onlyDate autoClose
                      noClearButton formatted="DD.MM.YYYY" button-now-translation="Heute" :state="input_state('datum_faelligkeit')" invalid-feedback="Bitte geben Sie eine Fälligkeit ein" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Betrag">
                    <b-input-group size="sm" append="€">
                      <b-form-input :value="betragString" :class="{ 'text-danger': betrag < 0 }" @focus="$event.target.select()" @blur="(event) =>
                        setPrice('betragString', event.target.value)
                        "></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Bezahlt">
                    <b-form-checkbox v-model="bezahlt" switch></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Lieferant" :state="input_state('lieferant')" invalid-feedback="Bitte ordnen Sie einen Lieferanten zu">
                    <v-select v-model="lieferant" :options="lieferant_suggestions" :filterable="false" label="name" taggable :get-option-label="(o) => `${o.nummer} ${o.name}`" class="size-sm" :class="{
                      'is-invalid': input_state('lieferant') === false,
                    }" @option:created="openAddLieferantModal" @search="onSearchLieferant">
                      <template #option="o">
                        <i class="mr-2" :class="o.icon"></i>
                        <span>{{ o.nummer }} {{ o.name }}</span>
                      </template>
                      <template #selected-option="o">
                        <i class="mr-2" :class="o.icon"></i>
                        <span>{{ o.nummer }} {{ o.name }}</span>
                      </template>
                      <template #no-options="{ search, searching }">
                        <span v-if="!search || search.length < 1">Bitte Suchtext zum Suchen des Lieferanten
                          eingeben</span>
                        <span v-else-if="!searching">
                          Keine Ergebnisse gefunden. Drücken Sie 'Enter' um
                          einen neuen Lieferanten anzulegen
                        </span>
                      </template>
                      <template #list-footer>
                        <li class="text-info text-center" style="font-size: 10px">
                          Zum Anlegen eines Lieferanten bitte Namen oder Nummer
                          eingeben und 'Enter' drücken
                        </li>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="8">
                  <b-form-group label="Abteilung">
                    <v-select v-model="zugeordnete_abteilung_id" :options="abteilungen" :clearable="false" label="titel" :reduce="(o) => o.id" class="size-sm"
                      :disabled="abteilungProp != null || freigegeben">
                      <template #option="o">
                        <i class="mr-2" :class="o.icon"></i>
                        <span>{{ o.titel }}</span>
                      </template>
                      <template #selected-option="o">
                        <i class="mr-2" :class="o.icon"></i>
                        <span>{{ o.titel }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Freizugeben Bis">
                    <vue-date-time-picker id="datum-freizugeben-bis" v-model="freizugeben_bis" size="sm" format="DD.MM.YYYY" earlier-translation="Früher" later-translation="Später" onlyDate autoClose
                      noClearButton formatted="DD.MM.YYYY" button-now-translation="Heute" :disabled="abteilungProp != null || freigegeben" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Kostenstelle">
                    <b-form-input v-model="kostenstelle" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Kostenträger/Projekt">
                    <b-form-input v-model="kostentraeger" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Zweck">
                    <b-form-input v-model="zweck" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Weiterberechnung an">
                    <b-form-input v-model="weiterberechnung_an" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="mit Auftrag">
                    <b-form-input v-model="mit_auftrag" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="mit Rechnung">
                    <b-form-input v-model="mit_rechnung" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Sonstiges">
                    <b-form-textarea rows="3" v-model="sonstiges" />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Barcode Position" v-slot="{ ariaDescribedby }" :disabled="barcode_use_coordinates">
                    <b-form-radio v-model="barcode_position" :aria-describedby="ariaDescribedby" name="barcode_position" :value="0" inline>Links</b-form-radio>
                    <b-form-radio v-model="barcode_position" :aria-describedby="ariaDescribedby" name="barcode_position" :value="1" inline>Mitte</b-form-radio>
                    <b-form-radio v-model="barcode_position" :aria-describedby="ariaDescribedby" name="barcode_position" :value="2" inline>Rechts</b-form-radio>
                  </b-form-group>
                  <div>
                    <b-form-checkbox v-model="barcode_use_coordinates" name="barcode_use_coordinates" switch :disabled="!barcode_x && !barcode_y">
                      Erweiterte Einstellungen
                      <i class="fas fa-info-circle" v-b-tooltip.hover :title="barcode_use_coordinates_info" />
                    </b-form-checkbox>
                    <b-row v-show="barcode_use_coordinates">
                      <b-col cols="6">
                        <b-form-group label="X-Position">
                          <b-form-input v-model.number="barcode_x" size="sm" />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Y-Position">
                          <b-form-input v-model.number="barcode_y" size="sm" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>

              <hr />

              <b-row v-if="geloescht">
                <b-col cols="12">
                  <b-alert show variant="danger" class="font-italic">
                    Gelöscht von {{ formatMitarbeiterFullName(value.geloescht_von) }} am
                    {{ geloeschtAmString }}
                  </b-alert>
                </b-col>
                <b-col cols="6">
                  <b-button variant="info" @click="wiederherstellen">
                    <i class="fas fa-trash-restore mr-2" />Wiederherstellen
                  </b-button>
                </b-col>
              </b-row>

              <b-row v-if="!geloescht">
                <b-col cols="6">
                  <b-button variant="success" class="w-100" @click="speichern">
                    <i class="fas fa-save mr-2" />
                    <span>Speichern</span>
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button v-if="value && !freigegeben" variant="danger" class="w-100" @click="$refs.deleteModal.show()">
                    <i class="fas fa-trash mr-2" />
                    <span>Löschen</span>
                  </b-button>
                </b-col>
              </b-row>

              <b-row v-if="value && !freigegeben">
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col v-if="!abgelehnt && !geloescht" cols="6">
                  <b-button variant="danger" class="w-100" @click="$refs.ablehnenModal.show()">
                    <i class="fas fa-hand-paper mr-2" />Ablehnen
                  </b-button>
                </b-col>
                <b-col cols="12" v-if="abgelehnt">
                  <b-alert show variant="danger">
                    <h5>Abgelehnt</h5>
                    <span class="font-italic" style="white-space: pre" v-html="abgelehntString"></span>
                  </b-alert>
                </b-col>
                <b-col cols="6" v-if="abgelehnt">
                  <b-button variant="info" class="w-100" @click="abgelehnungStornieren">Ablehnung zurückziehen</b-button>
                </b-col>
              </b-row>

              <b-row v-if="value && value.zugeordnete_abteilung_id !== null">
                <b-col cols="12">
                  <hr />
                  <h5>Sachliche Prüfung</h5>
                </b-col>
                <b-col v-if="abteilungProp !== null && !freigegeben" cols="6">
                  <b-button variant="info" class="w-100" :disabled="abteilungProp == null && !freigegeben" @click="freigeben">
                    <i class="ifr if-upload mr-2" />
                    <span>Freigeben</span>
                  </b-button>
                </b-col>
                <b-col v-if="freigegeben" cols="12">
                  <span class="font-italic">
                    Freigegeben von {{ formatMitarbeiterFullName(value.freigegeben_von) }} am
                    {{ freigegebenAmString }}
                  </span>
                </b-col>
              </b-row>
              <b-row v-if="exportiert">
                <b-col cols="12">
                  <hr />
                  <h5>Export</h5>
                </b-col>
                <b-col cols="12">
                  <span class="font-italic">
                    Exportiert von {{ formatMitarbeiterFullName(value.exportiert_von) }} am
                    {{ exportiertAmString }}
                  </span>
                </b-col>
              </b-row>
            </bx-box>
          </bx-box>
        </b-col>
        <b-col xl="8">
          <iframe :key="pdfKey" v-if="pdf_url" :src="pdf_url" width="100%" height="1200px" title="pdf"></iframe>
        </b-col>
      </b-row>
    </b-container>
    <b-modal ref="addLieferantModal" title="Lieferant hinzufügen" cancel-title="Abbrechen" @ok="addLieferant" @cancel="addLieferantCancel">
      <b-form @submit.prevent="addLieferant">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nummer">
              <b-form-input ref="create_lieferant_nummer" v-model.trim="create_lieferant_nummer" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Name">
              <b-form-input v-model.trim="create_lieferant_name" />
            </b-form-group>
          </b-col>
          <input type="submit" class="d-none" />
        </b-row>
      </b-form>
    </b-modal>
    <b-modal ref="ablehnenModal" title="Rechnung ablehnen" cancel-title="Abbrechen" centered :ok-disabled="!ablehnen_grund" @ok="ablehnen" @cancel="cancelAblehnen">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Grund">
            <b-form-textarea rows="5" v-model="ablehnen_grund" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="deleteModal" title="Rechnung löschen" cancel-title="Abbrechen" centered @ok="deleteRechnung">
      <p>Wollen Sie die Eingangsrechnung wirklich löschen?</p>
    </b-modal>
  </div>
</template>

<script>
import api from '@a/index';
import { DateTime } from 'luxon';
import _ from 'lodash';
import numeral from 'numeral';
import { required } from 'vuelidate/lib/validators';
import formular from '@m/formular';
import mitarbeiterFilter from '@/mixins/mitarbeiterFilter';

export default {
  components: {},
  name: 'EingangsrechnungDetail',
  mixins: [formular, mitarbeiterFilter],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    const { title } = this;
    return {
      titleTemplate: () => title,
    };
  },
  data() {
    return {
      abteilungProp: this.$route.query.abteilung || null,
      barcode_position: 0,
      barcode_use_coordinates: false,
      barcode_x: 0,
      barcode_y: 0,
      create_lieferant_name: null,
      create_lieferant_nummer: null,
      datum: null,
      freizugeben_bis: null,
      kostenstelle: null,
      kostentraeger: null,
      lieferant: null,
      lieferant_id: null,
      lieferant_suggestions: [],
      mit_auftrag: null,
      mit_rechnung: null,
      pdfKey: new Date().getTime(),
      value: null,
      datum_rechnung: null,
      datum_skonto: null,
      rechnungsnummer: null,
      betrag: null,
      bezahlt: null,
      datum_faelligkeit: null,
      vorgangsnummer: null,
      weiterberechnung_an: null,
      zugeordnete_abteilung_id: null,
      zweck: null,
      sonstiges: null,
      ablehnen_grund: null,
    };
  },
  validations: {
    lieferant: {
      required,
    },
    datum_skonto: {
      required,
    },
    datum_faelligkeit: {
      required,
    },
  },
  watch: {
    lieferant(val) {
      this.lieferant_id = val?.id;
    },
  },
  computed: {
    title() {
      return 'Eingangsrechnung';
    },
    pdf_url() {
      if (this.value && this.value.pdf) {
        return `${this.$env.serverUrl}${this.value.pdf.path}`;
      } if (this.value && this.value.original_pdf) {
        return `${this.$env.serverUrl}${this.value.original_pdf.path}`;
      }
      return null;
    },
    src_url() {
      return { url: this.pdf_url, withCredentials: true };
    },
    abteilungen() {
      return this.$store.state.abteilungen;
    },
    freigegeben() {
      return this.value && this.value.freigegeben_am !== null;
    },
    abgelehnt() {
      return this.value && this.value.abgelehnt_am !== null;
    },
    geloescht() {
      return this.value && this.value.geloescht_am !== null;
    },
    freigegebenAmString() {
      if (this.freigegeben) {
        return DateTime.fromMillis(this.value.freigegeben_am).toFormat(
          'dd.MM.yyyy HH:mm',
        );
      }
      return '';
    },
    abgelehntString() {
      if (this.abgelehnt) {
        return `von ${this.value.abgelehnt_von?.full_name}\nam ${DateTime.fromMillis(this.value.abgelehnt_am).toFormat('dd.MM.yyyy HH:mm')}\n<strong>Grund:</strong>\n${this.value.abgelehnt_grund}`;
      }
      return '';
    },
    exportiert() {
      return this.value && this.value.exportiert_am !== null;
    },
    exportiertAmString() {
      if (this.exportiert) {
        return DateTime.fromMillis(this.value.exportiert_am).toFormat(
          'dd.MM.yyyy HH:mm',
        );
      }
      return '';
    },
    geloeschtAmString() {
      if (this.geloescht) {
        return DateTime.fromMillis(this.value.geloescht_am).toFormat(
          'dd.MM.yyyy HH:mm',
        );
      }
      return '';
    },
    barcode_use_coordinates_info() {
      return `Falls der Barcode nicht an den oberen Rand der PDF-Datei gedruckt wird,
                                                                                                                                                                                                                                                                                                          können Sie mit dieser Einstellung die Koordinaten des Barcodes manuell erfassen.`;
    },
    betragString: {
      get() {
        return numeral(this.betrag).format('0,0.00');
      },
      set(val) {
        this.betrag = numeral(val).value();
      },
    },
  },
  async created() {
    await this.ladeEingangsrechnung();
  },
  methods: {
    initialize(eingangsrechnung) {
      this.value = eingangsrechnung;
      this.lieferant_id = eingangsrechnung.lieferant_id;
      this.lieferant = eingangsrechnung.lieferant;
      this.vorgangsnummer = eingangsrechnung.vorgangsnummer;
      this.kostenstelle = eingangsrechnung.kostenstelle;
      this.kostentraeger = eingangsrechnung.kostentraeger;
      this.zweck = eingangsrechnung.zweck;
      this.weiterberechnung_an = eingangsrechnung.weiterberechnung_an;
      this.mit_auftrag = eingangsrechnung.mit_auftrag;
      this.mit_rechnung = eingangsrechnung.mit_rechnung;
      this.sonstiges = eingangsrechnung.sonstiges;
      this.zugeordnete_abteilung_id = eingangsrechnung.zugeordnete_abteilung_id;
      this.barcode_position = eingangsrechnung.barcode_position;
      this.barcode_use_coordinates = eingangsrechnung.barcode_use_coordinates;
      this.datum_rechnung = eingangsrechnung.datum_rechnung
        ? DateTime.fromMillis(eingangsrechnung.datum_rechnung).toFormat(
          'dd.MM.yyyy',
        )
        : null;
      this.datum_skonto = eingangsrechnung.datum_skonto
        ? DateTime.fromMillis(eingangsrechnung.datum_skonto).toFormat(
          'dd.MM.yyyy',
        )
        : null;
      this.datum_faelligkeit = eingangsrechnung.datum_faelligkeit
        ? DateTime.fromMillis(eingangsrechnung.datum_faelligkeit).toFormat(
          'dd.MM.yyyy',
        )
        : null;
      this.rechnungsnummer = eingangsrechnung.rechnungsnummer;
      this.betrag = eingangsrechnung.betrag;
      this.bezahlt = eingangsrechnung.bezahlt;
      this.barcode_x = eingangsrechnung.barcode_x;
      this.barcode_y = eingangsrechnung.barcode_y;
      this.datum = eingangsrechnung.datum
        ? DateTime.fromMillis(eingangsrechnung.datum).toFormat('dd.MM.yyyy')
        : null;
      this.freizugeben_bis = eingangsrechnung.freizugeben_bis
        ? DateTime.fromMillis(eingangsrechnung.freizugeben_bis).toFormat(
          'dd.MM.yyyy',
        )
        : null;
    },
    async ladeEingangsrechnung() {
      const loader = this.$loader();
      try {
        const response = await api.eingangsrechnung.getById(this.id);
        this.initialize(response.data);
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    async speichern(showLoader = true, skipPdf = false) {
      if (this.$v.$invalid) {
        this.errors_activated = true;
        // this.$refs.validationFailureModal.show();
        return false;
      }
      const loader = showLoader ? this.$loader() : null;

      try {
        const json = {
          lieferant_id: this.lieferant?.id,
          vorgangsnummer: this.vorgangsnummer,
          kostenstelle: this.kostenstelle,
          kostentraeger: this.kostentraeger,
          zweck: this.zweck,
          weiterberechnung_an: this.weiterberechnung_an,
          mit_auftrag: this.mit_auftrag,
          mit_rechnung: this.mit_rechnung,
          sonstiges: this.sonstiges,
          zugeordnete_abteilung_id: this.zugeordnete_abteilung_id,
          barcode_position: this.barcode_position,
          barcode_use_coordinates: this.barcode_use_coordinates,
          barcode_x: this.barcode_x,
          barcode_y: this.barcode_y,
          datum: this.datum
            ? DateTime.fromFormat(this.datum, 'dd.MM.yyyy').ts
            : null,
          freizugeben_bis: this.freizugeben_bis
            ? DateTime.fromFormat(this.freizugeben_bis, 'dd.MM.yyyy').ts
            : null,
          datum_rechnung: this.datum_rechnung
            ? DateTime.fromFormat(this.datum_rechnung, 'dd.MM.yyyy').ts
            : null,
          datum_skonto: this.datum_skonto
            ? DateTime.fromFormat(this.datum_skonto, 'dd.MM.yyyy').ts
            : null,
          datum_faelligkeit: this.datum_faelligkeit
            ? DateTime.fromFormat(this.datum_faelligkeit, 'dd.MM.yyyy').ts
            : null,
          rechnungsnummer: this.rechnungsnummer,
          betrag: this.betrag,
          bezahlt: this.bezahlt,
        };

        const response = await api.eingangsrechnung.put(this.id, json, skipPdf);
        this.initialize(response.data);

        if (!skipPdf) this.pdfKey = new Date().getTime();

        if (showLoader) this.$notifySuccess('Eingangsrechnung erfolgreich gespeichert');

        return true;
      } catch (e) {
        this.$notifyError(e);
        return false;
      } finally {
        if (showLoader) loader.hide();
      }
    },
    async freigeben() {
      const loader = this.$loader();

      try {
        if (await this.speichern(false, true)) {
          const response = await api.eingangsrechnung.freigeben(this.id);
          this.initialize(response.data);

          this.pdfKey = new Date().getTime();

          this.$notifySuccess('Eingangsrechnung erfolgreich freigegeben');
        }
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    onSearchLieferant(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLieferant(loading, search, this);
      }
    },
    searchLieferant: _.debounce((loading, search, vm) => {
      api.lieferant.get(search).then((response) => {
        // eslint-disable-next-line no-param-reassign
        vm.lieferant_suggestions = response.data;
        loading(false);
      });
    }),
    openAddLieferantModal(search) {
      const numberRegex = /^[0-9]+$/;

      const str = search.name ? search.name : search;

      if (numberRegex.test(str)) {
        this.create_lieferant_nummer = str;
      } else {
        this.create_lieferant_name = str;
      }

      this.$refs.addLieferantModal.show();

      setTimeout(() => {
        this.$refs.create_lieferant_nummer.focus();
      }, 400);
    },
    async ablehnen() {
      const loader = this.$loader();
      try {
        const response = await api.eingangsrechnung.ablehnen(
          this.id,
          this.ablehnen_grund,
        );
        this.initialize(response.data);
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    async abgelehnungStornieren() {
      const loader = this.$loader();
      try {
        const response = await api.eingangsrechnung.ablehnenStornieren(this.id);
        this.ablehnen_grund = null;
        this.initialize(response.data);
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    cancelAblehnen() {
      this.abgelehnt_grund = null;
    },
    addLieferantCancel() {
      this.create_lieferant_nummer = null;
      this.create_lieferant_name = null;
    },
    async addLieferant() {
      const loader = this.$loader();
      try {
        const response = await api.lieferant.post(
          this.create_lieferant_nummer,
          this.create_lieferant_name,
        );
        this.lieferant = response.data;
        this.$refs.addLieferantModal.hide();
        this.$notifySuccess('Lieferant erfolgreich erstellt');
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    openDeleteModal() {
      this.$refs.deleteModal.show();
    },
    async deleteRechnung() {
      const loader = this.$loader();
      try {
        await api.eingangsrechnung.delete(this.id);

        this.$notifySuccess('Eingangsrechnung erfolgreich gelöscht');
        this.$router.go(-1);
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    async wiederherstellen() {
      const loader = this.$loader();
      try {
        const response = await api.eingangsrechnung.wiederherstellen(this.id);
        this.initialize(response.data);
        this.$notifySuccess('Eingangsrechnung erfolgreich wiederhergestellt');
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    setPrice(field, value) {
      const num = numeral(value);
      this.$set(this, field, num.format('0,0.00'));
    },
  },
};
</script>

<style></style>

import api from '@a/index';

import _ from 'lodash';
import {
  LOAD_WERKZEUGSTATI,
  LOAD_WERKZEUGTYPEN,
  LOAD_WERKZEUGGRUPPEN,
  LOAD_WERKZEUGAUSFUEHRUNGEN,
  LOAD_LAGERORTE,
  LOAD_KATEGORIEN,
  LOAD_WERKZEUGSUBSTATI,
  LOAD_ANZAHL_WERKZEUGBAU,
  LOAD_STANDORTE,
  ADD_LAGERORT,
  LOAD_ANZAHL_ANZAHL_KUERZLICH_ABGESCHLOSSEN,
  LOAD_ANZAHL_ANZAHL_ANGEBOTSERSTELLUNG,
} from './actions.type';
import {
  SET_WERKZEUGSTATI,
  SET_WERKZEUGSTATI_LOADED,
  SET_WERKZEUGTYPEN,
  SET_WERKZEUGTYPEN_LOADED,
  SET_WERKZEUGGRUPPEN,
  SET_WERKZEUGGRUPPEN_LOADED,
  SET_WERKZEUGAUSFUEHRUNGEN,
  SET_WERKZEUGAUSFUEHRUNGEN_LOADED,
  SET_LAGERORTE,
  SET_LAGERORTE_LOADED,
  SET_WERKZEUGSUBSTATI,
  SET_WERKZEUGSUBSTATI_LOADED,
  SET_ANZAHL_WERKZEUGBAU,
  SET_STANDORTE,
  SET_STANDORTE_LOADED,
  SET_ANZAHL_KUERZLICH_ABGESCHLOSSEN,
  SET_ANZAHL_ANGEBOTSERSTELLUNG,
} from './mutations.type';

import historie from './historie';
import { WERKZEUGBAU_SUBSTATI } from './getters.type';
import { HISTORIE } from './modules.type';

export default {
  namespaced: true,
  state: {
    werkzeugstati: [],
    _werkzeugstati_loaded: false,
    werkzeugsubstati: [],
    _werkzeugsubstati_loaded: false,
    werkzeugtypen: [],
    _werkzeugtypen_loaded: false,
    werkzeuggruppen: [],
    _werkzeuggruppen_loaded: false,
    werkzeugausfuehrungen: [],
    _werkzeugausfuehrungen_loaded: false,
    lagerorte: [],
    _lagerorte_loaded: false,
    standorte: [],
    _standorte_loaded: false,
    anzahl_werkzeugbau: null,
    anzahl_kuerzlich_abgeschlossen: null,
    anzahl_angebotserstellung: null,
  },
  mutations: {
    [SET_WERKZEUGSTATI](state, val) {
      state.werkzeugstati = val;
    },
    [SET_WERKZEUGSTATI_LOADED](state, val) {
      state._werkzeugstati_loaded = val;
    },
    [SET_WERKZEUGSUBSTATI](state, val) {
      state.werkzeugsubstati = val;
    },
    [SET_WERKZEUGSUBSTATI_LOADED](state, val) {
      state._werkzeugsubstati_loaded = val;
    },
    [SET_WERKZEUGTYPEN](state, val) {
      state.werkzeugtypen = val;
    },
    [SET_WERKZEUGTYPEN_LOADED](state, val) {
      state._werkzeugtypen_loaded = val;
    },
    [SET_WERKZEUGGRUPPEN](state, val) {
      state.werkzeuggruppen = val;
    },
    [SET_WERKZEUGGRUPPEN_LOADED](state, val) {
      state._werkzeuggruppen_loaded = val;
    },
    [SET_WERKZEUGAUSFUEHRUNGEN](state, val) {
      state.werkzeugausfuehrungen = val;
    },
    [SET_WERKZEUGAUSFUEHRUNGEN_LOADED](state, val) {
      state._werkzeugausfuehrungen_loaded = val;
    },
    [SET_LAGERORTE](state, val) {
      state.lagerorte = val;
    },
    [SET_LAGERORTE_LOADED](state, val) {
      state._lagerorte_loaded = val;
    },
    [SET_STANDORTE](state, val) {
      state.standorte = val;
    },
    [SET_STANDORTE_LOADED](state, val) {
      state._standorte_loaded = val;
    },
    [SET_ANZAHL_WERKZEUGBAU](state, val) {
      state.anzahl_werkzeugbau = val;
    },
    [SET_ANZAHL_KUERZLICH_ABGESCHLOSSEN](state, val) {
      state.anzahl_kuerzlich_abgeschlossen = val;
    },
    [SET_ANZAHL_ANGEBOTSERSTELLUNG](state, val) {
      state.anzahl_angebotserstellung = val;
    },
  },
  actions: {
    async [LOAD_WERKZEUGSTATI]({ commit, state }, force = false) {
      if (state._werkzeugstati_loaded && !force) {
        return state.werkzeugstati;
      }
      const response = await api.v1.werkzeug.status.get();
      commit(SET_WERKZEUGSTATI, response.data);
      commit(SET_WERKZEUGSTATI_LOADED, true);
      return response.data;
    },
    async [LOAD_WERKZEUGSUBSTATI]({ commit, state }, force = false) {
      if (state._werkzeugsubstati_loaded && !force) {
        return state.werkzeugsubstati;
      }
      const response = await api.v1.werkzeug.status.getSubStati();
      commit(SET_WERKZEUGSUBSTATI, response.data);
      commit(SET_WERKZEUGSUBSTATI_LOADED, true);
      return response.data;
    },
    async [LOAD_WERKZEUGTYPEN]({ commit, state }, force = false) {
      if (state._werkzeugtypen_loaded && !force) {
        return state.werkzeugtypen;
      }
      const response = await api.v1.werkzeug.typ.get();
      commit(SET_WERKZEUGTYPEN, response.data);
      commit(SET_WERKZEUGTYPEN_LOADED, true);
      return response.data;
    },
    async [LOAD_WERKZEUGGRUPPEN]({ commit, state }, force = false) {
      if (state._werkzeuggruppen_loaded && !force) {
        return state.werkzeuggruppen;
      }
      const response = await api.v1.werkzeug.gruppe.get();
      commit(SET_WERKZEUGGRUPPEN, response.data);
      commit(SET_WERKZEUGGRUPPEN_LOADED, true);
      return response.data;
    },
    async [LOAD_WERKZEUGAUSFUEHRUNGEN]({ commit, state }, force = false) {
      if (state._werkzeugausfuehrungen_loaded && !force) {
        return state.werkzeugausfuehrungen;
      }
      const response = await api.v1.werkzeug.ausfuehrung.get();
      commit(SET_WERKZEUGAUSFUEHRUNGEN, response.data);
      commit(SET_WERKZEUGAUSFUEHRUNGEN_LOADED, true);
      return response.data;
    },
    async [LOAD_LAGERORTE]({ commit, state }, force = false) {
      if (state._lagerorte_loaded && !force) {
        return state.lagerorte;
      }
      const response = await api.v1.lagerort.get();
      commit(SET_LAGERORTE, response.data.content);
      commit(SET_LAGERORTE_LOADED, true);
      return response.data.content;
    },
    async [LOAD_STANDORTE]({ commit, state }, force = false) {
      if (state._standorte_loaded && !force) {
        return state.standorte;
      }
      const response = await api.v1.werkzeug.standort.get();
      commit(SET_STANDORTE, response.data);
      commit(SET_STANDORTE_LOADED, true);
      return response.data;
    },
    async [LOAD_ANZAHL_WERKZEUGBAU]({ commit }) {
      const response = await api.v1.werkzeug.werkzeugbau.getAnzahl();
      commit(SET_ANZAHL_WERKZEUGBAU, response.data);
      return response.data;
    },
    async [LOAD_ANZAHL_ANZAHL_KUERZLICH_ABGESCHLOSSEN]({ commit }) {
      const response = await api.v1.werkzeug.getAnzahlKuerzlichAbgeschlossen();
      commit(SET_ANZAHL_KUERZLICH_ABGESCHLOSSEN, response.data);
      return response.data;
    },
    async [LOAD_ANZAHL_ANZAHL_ANGEBOTSERSTELLUNG]({ commit }) {
      const response = await api.v1.werkzeug.getAnzahlAngebotserstellung();
      commit(SET_ANZAHL_ANGEBOTSERSTELLUNG, response.data);
      return response.data;
    },
    async [LOAD_KATEGORIEN]({ dispatch }) {
      await Promise.all([
        dispatch(LOAD_WERKZEUGSTATI),
        dispatch(LOAD_WERKZEUGSUBSTATI),
        dispatch(LOAD_WERKZEUGTYPEN),
        dispatch(LOAD_WERKZEUGGRUPPEN),
        dispatch(LOAD_WERKZEUGAUSFUEHRUNGEN),
        dispatch(LOAD_LAGERORTE),
        dispatch(LOAD_STANDORTE),
      ]);
    },
    async [ADD_LAGERORT]({ state, commit }, lagerort) {
      const response = await api.v1.lagerort.post(lagerort);
      const neueLagerorte = state.lagerorte;
      neueLagerorte.push(response.data);
      commit(SET_LAGERORTE, _.sortBy(neueLagerorte, 'name'));
      return response.data;
    },
  },
  modules: {
    [HISTORIE]: historie,
  },
  getters: {
    [WERKZEUGBAU_SUBSTATI](state) {
      return _.chain(state.werkzeugsubstati).filter((e) => e.werkzeugbau === true).sortBy((e) => e.sort).value();
    },
  },
};

import messmittelkategorie from '@/static/messmittel/messmittelkategorie';

export default [
  {
    path: 'messmittelverwaltung',
    component: () => import('@/layouts/messmittelverwaltung/Messmittelverwaltung.vue'),
    children: [
      {
        name: 'messmittelverwaltung',
        path: '',
        component: () => import('@v/messmittelverwaltung/MessmittelverwaltungStartseite.vue'),
      },
      {
        name: 'messmittelliste',
        path: 'messmittel',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
        },
      },
      {
        name: 'messmittelliste.typ',
        path: 'messmittel/typ/:werkzeugtyp',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          werkzeugtyp: route.params.werkzeugtyp,
        }),
      },
      {
        name: 'messmittelliste.gruppe',
        path: 'messmittel/gruppe/:werkzeuggruppe',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          werkzeuggruppe: route.params.werkzeuggruppe,
        }),
      },
      {
        name: 'messmittelliste.status',
        path: 'messmittel/status/:status',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          status: route.params.status,
        }),
      },
      {
        name: 'messmittelliste.pruefung_vorgemerkt',
        path: 'messmittel/pruefung_vorgemerkt',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
          pruefungVorgemerkt: true,
        },
      },
      {
        name: 'messmittelliste.pruefung_ueberfaellig',
        path: 'messmittel/pruefung_ueberfaellig',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
          pruefungUeberfaellig: true,
        },
      },
      {
        name: 'messmittelliste.extern',
        path: 'messmittel/extern',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
          extern: true,
        },
      },
      {
        name: 'messmittelliste.extern.typ',
        path: 'messmittel/extern/typ/:werkzeugtyp',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          werkzeugtyp: route.params.werkzeugtyp,
          extern: true,
        }),
      },
      {
        name: 'messmittelliste.extern.gruppe',
        path: 'messmittel/extern/gruppe/:werkzeuggruppe',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          werkzeuggruppe: route.params.werkzeuggruppe,
          extern: true,
        }),
      },
      {
        name: 'messmittelliste.extern.status',
        path: 'messmittel/extern/status/:status',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: (route) => ({
          canAdd: true,
          status: route.params.status,
          extern: true,
        }),
      },
      {
        name: 'messmittelliste.extern.pruefung_vorgemerkt',
        path: 'messmittel/extern/pruefung_vorgemerkt',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
          pruefungVorgemerkt: true,
          extern: true,
        },
      },
      {
        name: 'messmittelliste.extern.pruefung_ueberfaellig',
        path: 'messmittel/extern/pruefung_ueberfaellig',
        component: () => import('@/views/messmittelverwaltung/messmittel/Messmittelliste.vue'),
        props: {
          canAdd: true,
          pruefungUeberfaellig: true,
          extern: true,
        },
      },
      {
        name: 'messmittel.import',
        path: 'messmittel/import',
        component: () => import('@v/messmittelverwaltung/messmittel/import/Index.vue'),
      },
      {
        name: 'messmittel.neu',
        path: 'messmittel/neu',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: {
          id: null,
          edit: true,
          kategorie: messmittelkategorie.messmittel,
        },
      },
      {
        name: 'pruefmittel.neu',
        path: 'pruefmittel/neu',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: {
          id: null,
          edit: true,
          kategorie: messmittelkategorie.pruefmittel,
        },
      },
      {
        name: 'messmittel.neu.tab',
        path: 'messmittel/neu/:primarytab',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: (route) => ({
          id: null,
          edit: true,
          tab: null,
          primarytab: route.params.primarytab,
        }),
      },
      {
        name: 'messmittel.id',
        path: 'messmittel/:id',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: false,
        }),
      },
      {
        name: 'messmittel.id.edit',
        path: 'messmittel/:id/edit',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
        }),
      },
      {
        name: 'messmittel.kalibrierschein',
        path: 'messmittel/:werkzeug_id/kalibrierschein',
        component: () => import('@v/messmittelverwaltung/messmittel/kalibrierung/Kalibrierschein.vue'),
        props: true,
      },
      {
        name: 'messmittel.id.tab',
        path: 'messmittel/:id/:primarytab/:tab',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: (route) => ({

          id: route.params.id,
          edit: false,
          tab: route.params.tab,
          primarytab: route.params.primarytab,
        }),
      },
      {
        name: 'messmittel.id.edit.tab',
        path: 'messmittel/:id/edit/:primarytab/:tab',
        component: () => import('@v/messmittelverwaltung/messmittel/Messmittel.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
          tab: route.params.tab,
          primarytab: route.params.primarytab,
        }),
      },
      {
        path: 'settings',
        component: () => import('@/layouts/messmittelverwaltung/Settings.vue'),
        children: [
          {
            path: 'historiekommentarmuster',
            name: 'messmittel.settings.historiekommentarmuster',
            component: () => import('@v/messmittelverwaltung/messmittel/settings/HistorieKommentarMuster/List.vue'),
          },
          {
            path: 'historiekommentarmuster/neu',
            name: 'messmittel.settings.historiekommentarmuster.neu',
            component: () => import('@v/messmittelverwaltung/messmittel/settings/HistorieKommentarMuster/Index.vue'),
          },
          {
            path: 'historiekommentarmuster/:id',
            name: 'messmittel.settings.historiekommentarmuster.id',
            component: () => import('@v/messmittelverwaltung/messmittel/settings/HistorieKommentarMuster/Index.vue'),
            props: true,
          },
        ],
      },
    ],
  },
];

import api from '@a/config';

import typ from './typ';

const path = 'v1/messmittel/historie';

export default {
  get: (messmittelId) => api.get(path, { params: { messmittel: messmittelId } }),
  typ,
};

import api from '@a/config';

const path = 'v1/lagerort';

export default {
  get: (page, size, sort = null, desc = false) => api.get(path, {
    params: {
      page, size, sort, desc,
    },
  }),
  post: (lagerort) => api.post(path, lagerort),
};

import api from '@a/index';

import {
  LOAD_EINGABEARTEN, LOAD_INTERVALLARTEN, LOAD_PRUEFAKTIONEN, LOAD_PROZESSKONTROLLKARTE_STATI, LOAD_KATEGORIEN, LOAD_FACHBEREICHE, LOAD_TEILEGRUPPEN,
} from './actions.type';
import {
  SET_EINGABEARTEN, SET_EINGABEARTEN_LOADED, SET_FACHBEREICHE, SET_FACHBEREICHE_LOADED, SET_INTERVALLARTEN, SET_INTERVALLARTEN_LOADED, SET_PROZESSKONTROLLKARTE_STATI, SET_PROZESSKONTROLLKARTE_STATI_LOADED, SET_PRUEFAKTIONEN, SET_PRUEFAKTIONEN_LOADED, SET_TEILEGRUPPEN, SET_TEILEGRUPPEN_LOADED,
} from './mutations.type';

export default {
  namespaced: true,
  state: {
    eingabearten: [],
    _eingabearten_loaded: false,
    intervallarten: [],
    _intervallarten_loaded: false,
    pruefaktionen: [],
    _pruefaktionen_loaded: false,
    prozesskontrollkarte_stati: [],
    _prozesskontrollkarte_stati_loaded: false,
    fachbereiche: [],
    _fachbereiche_loaded: false,
    teilegruppen: [],
    _teilegruppen_loaded: false,
    abschlussgrund: [
      'Material alle',
      'Werkzeuge reparieren',
      'Maschine defekt',
    ],
  },
  mutations: {
    [SET_EINGABEARTEN](state, val) {
      state.eingabearten = val;
    },
    [SET_EINGABEARTEN_LOADED](state, val) {
      state._eingabearten_loaded = val;
    },
    [SET_INTERVALLARTEN](state, val) {
      state.intervallarten = val;
    },
    [SET_INTERVALLARTEN_LOADED](state, val) {
      state._intervallarten_loaded = val;
    },
    [SET_PRUEFAKTIONEN](state, val) {
      state.pruefaktionen = val;
    },
    [SET_PRUEFAKTIONEN_LOADED](state, val) {
      state._pruefaktionen_loaded = val;
    },
    [SET_PROZESSKONTROLLKARTE_STATI](state, val) {
      state.prozesskontrollkarte_stati = val;
    },
    [SET_PROZESSKONTROLLKARTE_STATI_LOADED](state, val) {
      state._prozesskontrollkarte_stati_loaded = val;
    },
    [SET_FACHBEREICHE](state, val) {
      state.fachbereiche = val;
    },
    [SET_FACHBEREICHE_LOADED](state, val) {
      state._fachbereiche_loaded = val;
    },
    [SET_TEILEGRUPPEN](state, val) {
      state.teilegruppen = val;
    },
    [SET_TEILEGRUPPEN_LOADED](state, val) {
      state._teilegruppen_loaded = val;
    },

  },
  actions: {
    async [LOAD_EINGABEARTEN]({ commit, state }, force = false) {
      if (state._eingabearten_loaded && !force) {
        return state.eingabearten;
      }
      const response = await api.v1.prozesskontrollkarte.eingabeart.get();
      commit(SET_EINGABEARTEN, response.data);
      commit(SET_EINGABEARTEN_LOADED, true);
      return response.data;
    },
    async [LOAD_INTERVALLARTEN]({ commit, state }, force = false) {
      if (state._intervallarten_loaded && !force) {
        return state.intervallarten;
      }
      const response = await api.v1.prozesskontrollkarte.intervallart.get();
      commit(SET_INTERVALLARTEN, response.data);
      commit(SET_INTERVALLARTEN_LOADED, true);
      return response.data;
    },
    async [LOAD_PRUEFAKTIONEN]({ commit, state }, force = false) {
      if (state._pruefaktionen_loaded && !force) {
        return state.pruefaktionen;
      }
      const response = await api.v1.prozesskontrollkarte.pruefaktion.get();
      commit(SET_PRUEFAKTIONEN, response.data);
      commit(SET_PRUEFAKTIONEN_LOADED, true);
      return response.data;
    },
    async [LOAD_PROZESSKONTROLLKARTE_STATI]({ commit, state }, force = false) {
      if (state._prozesskontrollkarte_stati_loaded && !force) {
        return state.prozesskontrollkarte_stati;
      }
      const response = await api.v1.prozesskontrollkarte.status.get();
      commit(SET_PROZESSKONTROLLKARTE_STATI, response.data);
      commit(SET_PROZESSKONTROLLKARTE_STATI_LOADED, true);
      return response.data;
    },
    async [LOAD_FACHBEREICHE]({ commit, state }, force = false) {
      if (state._fachbereiche_loaded && !force) {
        return state.fachbereiche;
      }
      const response = await api.v1.prozesskontrollkarte.fachbereich.get();
      commit(SET_FACHBEREICHE, response.data);
      commit(SET_FACHBEREICHE_LOADED, true);
      return response.data;
    },
    async [LOAD_TEILEGRUPPEN]({ commit, state }, force = false) {
      if (state._teilegruppen_loaded && !force) {
        return state.teilegruppen;
      }
      const response = await api.v1.infra.teilegruppe.get();
      commit(SET_TEILEGRUPPEN, response.data.teilegruppen);
      commit(SET_TEILEGRUPPEN_LOADED, true);
      return response.data;
    },
    async [LOAD_KATEGORIEN]({ dispatch }) {
      return Promise.all([
        dispatch(LOAD_EINGABEARTEN),
        dispatch(LOAD_INTERVALLARTEN),
        dispatch(LOAD_PRUEFAKTIONEN),
        dispatch(LOAD_PROZESSKONTROLLKARTE_STATI),
        dispatch(LOAD_FACHBEREICHE),
        dispatch(LOAD_TEILEGRUPPEN),
      ]);
    },
  },
  modules: {

  },
  getters: {
  },
};

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $notifySuccess(text) {
          this.$notify({
            type: 'success',
            title: 'Aktion erfolgreich',
            text,
          });
        },
        $notifyError(e) {
          console.error(e);
          if (e.response) {
            this.$notify({
              type: 'error',
              title: 'Aktion fehlerhaft',
              text: `Status ${e.response.status} - Fehler: ${e.response.data}`,
            });
          } else {
            this.$notify({
              type: 'error',
              title: 'Aktion fehlerhaft',
              text: e,
            });
          }
        },
      },
    });
  },
};

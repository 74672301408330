import _ from 'lodash';
import numeral from 'numeral';

export default {
  data() {
    return {
      errors_activated: false,
    };
  },
  methods: {
    input_state(key) {
      const parts = key.split('.');

      let obj = this.$v;

      parts.forEach((p) => {
        obj = obj[p];
      });
      return obj?.$invalid && this.errors_activated ? false : null;
    },
    $input_state(key) {
      return this.input_state(key);
    },
    $set_number(key, value) {
      const num = numeral(value);
      const parts = key.split('.');
      let obj = this;

      const property = _.last(parts);
      _.take(parts, parts.length - 1).forEach((p) => {
        obj = obj[p];
      });

      this.$set(obj, property, num.value());
    },
  },
};

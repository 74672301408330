import api from '@a/config';

const path = 'v1/prozesskontrollkarte/fertigungsauftrag/schicht/pruefung';

export default {
  getById: (id) => api.get(`${path}/${id}`),
  create: (schicht) => api.post(path, schicht),
  update: (id, pruefung) => {
    const upd = {
      pruefung_nio: pruefung.pruefung_nio,
      pruefung_value: pruefung.pruefung_value,
      pruefung_bemerkung: pruefung.pruefung_bemerkung,
    };

    return api.put(`${path}/${id}`, upd);
  },
};

import _ from 'lodash';

const imageIcon = 'fas fa-file-image text-info';
const videoIcon = 'fas fa-file-video text-info';
const wordIcon = 'fas fa-file-word text-info';
const excelIcon = 'fas fa-file-excel text-success';
const powerpointIcon = 'fas fa-file-powerpoint';
const archiveIcon = 'fas fa-file-archive';
const defaultIcon = 'fas fa-file-alt';
const csvIcon = 'fas fa-file-csv';
const pdfIcon = 'far fa-file-pdf text-danger';

export const icons = {
  image: 'fas fa-file-image file-color-image',
  pdf: 'far fa-file-pdf file-color-pdf',
  word: 'fas fa-file-word file-color-word',
  powerpoint: 'fas fa-file-powerpoint file-color-power-point',
  excel: 'fas fa-file-excel file-color-csv',
  csv: 'fas fa-file-csv file-color-excel',
  audio: 'fas fa-file-audio file-color-audio',
  video: 'fas fa-file-video file-color-video',
  archive: 'fas fa-file-archive file-color-archive',
  code: 'fas fa-file-code file-color-code',
  text: 'fas fa-file-alt file-color-text',
  file: 'fas fa-file file-color-text',
  folder: 'fas fas fa-folder file-color-folder',
  folder_open: 'fas fa-folder-open file-color-folder',
};

const fileToIconAssignment = {

  // Images

  png: imageIcon,
  jpg: imageIcon,
  jpeg: imageIcon,
  bmp: imageIcon,
  svg: imageIcon,
  webp: imageIcon,
  apng: imageIcon,
  avif: imageIcon,
  tiff: imageIcon,
  ico: imageIcon,

  // Documents

  pdf: pdfIcon,
  doc: wordIcon,
  docx: wordIcon,
  docm: wordIcon,
  odt: wordIcon,
  xls: excelIcon,
  xlsx: excelIcon,
  xlsm: excelIcon,
  ods: excelIcon,
  ppt: powerpointIcon,
  pptx: powerpointIcon,
  pptm: powerpointIcon,
  odp: powerpointIcon,
  csv: csvIcon,

  // Video

  mp4: videoIcon,
  m4v: videoIcon,
  m4p: videoIcon,
  wmv: videoIcon,
  mkv: videoIcon,
  '3gp': videoIcon,
  '3g2': videoIcon,
  webm: videoIcon,
  flv: videoIcon,
  vob: videoIcon,
  ogv: videoIcon,
  ogg: videoIcon,
  drc: videoIcon,
  gif: videoIcon,
  gifv: videoIcon,
  mng: videoIcon,
  avi: videoIcon,
  mts: videoIcon,
  m2ts: videoIcon,
  ts: videoIcon,
  mov: videoIcon,
  qt: videoIcon,
  yuv: videoIcon,
  rm: videoIcon,
  rmvb: videoIcon,
  viv: videoIcon,
  asf: videoIcon,
  amv: videoIcon,
  svi: videoIcon,
  mxf: videoIcon,
  roq: videoIcon,
  nsv: videoIcon,
  f4v: videoIcon,
  f4p: videoIcon,
  f4a: videoIcon,
  f4b: videoIcon,

  // archives

  zip: archiveIcon,
  '7z': archiveIcon,
  rar: archiveIcon,
  tgz: archiveIcon,
  tar: archiveIcon,
  gz: archiveIcon,
  'tar.gz': archiveIcon,
};

function iconFromExtension(extension) {
  const val = fileToIconAssignment[_.lowerCase(extension)];
  return val || icons.text;
}

function getExtenstionFromName(name) {
  return _.chain(name).split('.').drop(1).join('.')
    .lowerCase()
    .value();
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function isImage(file) {
  return file?.mimeType?.startsWith('image/') || false;
}

function isVideo(file) {
  return file?.mimeType?.startsWith('video/') || false;
}

function isPdf(file) {
  return file?.mimeType === 'application/pdf';
}

export default {
  iconFromExtension,
  iconFromName(name) {
    return iconFromExtension(getExtenstionFromName(name));
  },
  getExtenstionFromName,
  humanFileSize(size) {
    if (size === 0) return '0 B';
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
  },
  getBase64,
  defaultIcon,
  isGallerySuitable(file) {
    return isImage(file);
  },
  isImage(file) {
    return isImage(file);
  },
  isVideo(file) {
    return isVideo(file);
  },
  isPdf(file) {
    return isPdf(file);
  },
};

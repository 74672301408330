import api from '@a/config';
import status from './status';
import typ from './typ';
import gruppe from './gruppe';
import historie from './historie';
import settings from './settings';
import standort from './standort';
import messbedingung from './messbedingung';
import messverfahren from './messverfahren';
import pruefvorschrift from './pruefvorschrift';
import kalibrierung from './kalibrierung';
import kategorie from './kategorie';

const path = 'v1/messmittel';

export default {
  get: (
    page,
    size,
    sort = null,
    desc = false,
    suche = null,
    sucheKunde = null,
    werkzeugtyp = null,
    werkzeuggruppe = null,
    standortParam = null,
    statusParam = null,
    status2 = null,
    werkzeugbau = null,
    pruefung_vorgemerkt = null,
    pruefung_ueberfaellig = null,
    extern = false,
  ) => api.get(path, {
    params: {
      page, size, sort, desc, suche, werkzeugtyp, werkzeuggruppe, standort: standortParam, status: statusParam, sucheKunde, status2, werkzeugbau, pruefung_vorgemerkt, pruefung_ueberfaellig, extern,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  getByWerkzeugnummer: (werkzeugnummer) => api.get(`${path}/by_werkzeugnummer/${werkzeugnummer}`),
  getHistorie: (id, page, size, sort = null, desc = false) => api.get(`${path}/${id}/historie`, {
    params: {
      page, size, sort, desc,
    },
  }),
  addHistorie: (id, formData) => api.post(`${path}/${id}/historie`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  changeHistorie: (messmittelId, historieId, formData) => api.put(`${path}/${messmittelId}/historie/${historieId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  deleteHistorie: (messmittelId, historieId) => api.delete(`${path}/${messmittelId}/historie/${historieId}`),
  getDokumente: (id, page, size, sort = null, desc = false, typParam = null, suche = null) => api.get(`${path}/${id}/dokument`, {
    params: {
      page, size, sort, desc, typ: typParam, suche,
    },
  }),
  create: (werkzeug) => api.post(path, werkzeug),
  update: (id, werkzeug) => api.put(`${path}/${id}`, werkzeug),
  addDocument: (id, formData) => api.post(`${path}/${id}/dokument`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  getAnzahl(extern = false) {
    return api.get(`${path}/anzahl`, { params: { extern } });
  },
  standort,
  status,
  typ,
  gruppe,
  historie,
  settings,
  messbedingung,
  messverfahren,
  pruefvorschrift,
  kalibrierung,
  kategorie,
  importCsv: (formdata) => api.post(`${path}/import`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  importInaktive: (formdata) => api.post(`${path}/import/inaktiv`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  messmittelnummerExists: (messmittelnummer, id) => api.get(`${path}/nummerexists`, { params: { messmittelnummer, id } }),
  delete: (id) => api.delete(`${path}/${id}`),
};

import api from '@a/config';

const path = 'v1/infra/fertigungsauftrag';

export default {
  get: (page, size, sort = null, desc = false, suche = null, status = null, zustand = null, teilegruppe = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, status, zustand, teilegruppe,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  getOhnePck: (page, size, sort = null, desc = false, suche = null, zustand = null, teilegruppe = null) => api.get(`${path}/ohne_pck`, {
    params: {
      page, size, sort, desc, suche, zustand, teilegruppe,
    },
  }),
  importOhnePck: () => api.post(`${path}/ohne_pck/import`),
  getPositionenOhnePck: (auftragsnummer) => api.get(`${path}/ohne_pck/positionen/${auftragsnummer}`),
};

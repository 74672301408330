export const SET_EINGABEARTEN = 'set_eingabearten';
export const SET_EINGABEARTEN_LOADED = 'set_eingabearten_loaded';

export const SET_INTERVALLARTEN = 'set_intervallarten';
export const SET_INTERVALLARTEN_LOADED = 'set_intervallarten_loaded';

export const SET_PRUEFAKTIONEN = 'set_pruefaktionen';
export const SET_PRUEFAKTIONEN_LOADED = 'set_pruefaktionen_loaded';

export const SET_PROZESSKONTROLLKARTE_STATI = 'set_prozesskontrollkarte_stati';
export const SET_PROZESSKONTROLLKARTE_STATI_LOADED = 'set_prozesskontrollkarte_stati_loaded';

export const SET_FACHBEREICHE = 'set_fachbereiche';
export const SET_FACHBEREICHE_LOADED = 'set_fachbereiche_loaded';

export const SET_TEILEGRUPPEN = 'set_teilegruppen';
export const SET_TEILEGRUPPEN_LOADED = 'set_teilegruppen_loaded';

import api from '@a/config';
import stueckliste from './stueckliste';

const path = 'v1/infra/artikel';

export default {
  search: (page, size, sort = null, desc = false, suche = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche,
    },
  }),
  stueckliste,
};

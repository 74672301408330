import api from '@a/config';

import eingabeart from './eingabeart';
import pruefaktion from './pruefaktion';
import status from './status';
import intervallart from './intervallart';
import pruefmerkmalvorlage from './pruefmerkmalvorlage';
import kontrollintervall from './kontrollintervall';
import admin from './admin';
import fertigungsauftrag from './fertigungsauftrag';
import schicht from './schicht';
import pruefung from './pruefung';
import fachbereich from './fachbereich';

const path = 'v1/prozesskontrollkarte';

export default {
  get: (
    statusParam,
    erzeugnis,
    artikelnummer,
    zeichnungsnummer,
    zeichnungsindex,
    arbeitsgang,
    abteilung,
    position,
    page,
    size,
    sort = null,
    desc = false,
    suche = null,
  ) => api.get(path, {
    params: {
      status: statusParam, erzeugnis, artikelnummer, zeichnungsnummer, zeichnungsindex, arbeitsgang, abteilung, position, page, size, sort, desc, suche,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  create: (prozesskontrollkarte) => api.post(path, prozesskontrollkarte),
  update: (id, prozesskontrollkarte) => api.put(`${path}/${id}`, prozesskontrollkarte),
  export: () => api.get(`${path}/export`),
  eingabeart,
  pruefaktion,
  status,
  intervallart,
  pruefmerkmalvorlage,
  kontrollintervall,
  admin,
  fertigungsauftrag,
  schicht,
  pruefung,
  fachbereich,
};

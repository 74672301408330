<template>
  <b-navbar type="dark" variant="cosmos" sticky class="align-items-center" :class="{ 'mb-3': !noSpaceAfter }">
    <b-navbar-nav align="left" justified class="col-2 col-md-3">
      <b-navbar-brand class="py-0" :to="zentraleTo" v-b-tooltip.hover title="Zur Startseite">
        <span class="ifr if-zentrale" style="font-size: 38px">
          <span class="path1 text-white" />
          <span class="path2 text-white" />
        </span>
      </b-navbar-brand>
      <b-navbar-brand class="py-0" role="button" v-b-tooltip.hover title="Zurück" @click="$router.back()">
        <span class="fas fa-arrow-left" style="font-size: 38px"> </span>
      </b-navbar-brand>
      <!-- <b-navbar-brand class="py-0" role="button" @click="$router.go(1)">
        <span class="fas fa-arrow-right" style="font-size: 38px"> </span>
      </b-navbar-brand> -->
    </b-navbar-nav>

    <b-navbar-nav align="center" justified class="col-8 col-md-6">
      <div class="text-center text-white nav-headline">
        <h2 class="font-size-xl"><span v-if="$env.isTestsystem" class="text-danger">[Testsystem]</span> {{ titleOutput }}</h2>
      </div>
    </b-navbar-nav>

    <b-navbar-nav align="right" class="col-2 col-md-3">
      <b-nav-item-dropdown right no-caret text="User" class="p-0">
        <template #button-content>
          <div class="user-sign text-white align-middle">
            <i class="ifr if-profil align-middle" />
            <span class="ml-2 font-italic h6 allign-middle d-none d-md-inline">{{ username }}</span>
          </div>
        </template>
        <b-dropdown-item v-if="page && canBerechtigungenVergeben" :href="'/bx-casl/module/bykey/' + page" target="_blank">
          <i class="fas fa-key mr-2" />Berechtigungen verwalten
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'change-password' }">
          <i class="fas fa-asterisk mr-2" />Passwort ändern
        </b-dropdown-item>
        <b-dropdown-item-button @click="logout">
          <i class="fas fa-sign-out-alt mr-2" />Logout
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { LOGOUT } from '@st/session/actions.type';
import usergroups from '@/config/user/groups';

export default {
  name: 'Navbar',
  props: {
    title: {
      type: String,
    },
    page: {
      type: String,
    },
    zentraleTo: {
      type: Object,
      default: () => ({ name: 'startseite' }),
    },
    ability: {
      type: String,
      default: 'access',
    },
    noSpaceAfter: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo() {
    const { title } = this;
    return {
      titleTemplate: () => title,
    };
  },
  created() {
    if (this.page && this.ability) {
      if (!this.$can(this.ability, this.page)) {
        this.$router.replace({ name: 'forbidden' });
      }
    }
  },
  computed: {
    username() {
      return this.$store.getters['session/username'];
    },
    canBerechtigungenVergeben() {
      return this.$hasGroup(usergroups.berechtigungen_verwalten);
    },
    titleOutput() {
      return this.title || '';
    },
  },
  methods: {
    async logout() {
      const loader = this.$loader();
      try {
        await this.$store.dispatch(`session/${LOGOUT}`);
      } finally {
        loader.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-headline {
  color: white;
  font-family: "khand";

  h2 {
    margin-bottom: 0px;
  }
}

.user-sign {
  line-height: 20px;
  font-size: 24px;
}

.if-zentrale {

  .path1,
  .path2 {
    &::before {
      color: white;
    }
  }
}
</style>

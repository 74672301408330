import eingangsrechnung from './eingangsrechnung';
import werkzeug from './werkzeug';
import messmittel from './messmittel';
import prozesskontrollkarte from './prozesskontrollkarte';
import abmusterung from './abmusterung';
import lagerort from './lagerort';
import dokument from './dokument';
import infra from './infra';

export default {
  eingangsrechnung,
  werkzeug,
  messmittel,
  lagerort,
  dokument,
  prozesskontrollkarte,
  infra,
  abmusterung,
};

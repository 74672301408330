import api from '../../config';

const path = 'export/werkzeug';

export default {
  get: (suche = null, sucheKunde = null, werkzeugtyp = null, status = null, status2 = null) => api.get(path, {
    params: {
      suche, werkzeugtyp, status, sucheKunde, status2,
    },
  }),
};

import store from '@st/index';
import { SESSION } from '@st/modules.type';
import { GET_USER_ID } from '@st/session/getters.type';

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $currentUser() {
          return store.state.session.user;
        },
        $currentUserId() {
          return store.getters[`${SESSION}/${GET_USER_ID}`];
        },
        $currentUsername() {
          return store.getters['session/username'];
        },
      },
    });
  },
};

<template>
  <div id="app" class="position-relative" :class="{ 'testsystem': $env.isTestsystem }">
    <notifications></notifications>
    <div class="pb-3">
      <router-view v-if="$store.state.session.user !== null || $route.name === 'login'" />
    </div>
    <file-modal ref="fileModal"></file-modal>

    <div class="text-right font-size-xs position-absolute text-steel d-print-none" style="right: 3px; bottom: 3px">
      v {{ version }}
    </div>
  </div>
</template>

<script>
import FileModal from '@c/FileModal.vue';
import { DateTime } from 'luxon';
import EventBus, { SHOW_FILE } from './event-bus';
import { SET_CURRENT_TIMESTAMP } from './store/mutations.type';
import { SESSION } from './store/modules.type';
import { SEND_CURRENT_URI } from './store/session/actions.type';

const version = process.env.VUE_APP_VERSION;

export default {
  name: 'App',
  components: {
    FileModal,
  },
  data() {
    return {
      time_inteval: null,
    };
  },
  created() { },
  computed: {
    version() {
      return version;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.dispatch(`${SESSION}/${SEND_CURRENT_URI}`);
      },
    },

  },
  mounted() {
    const self = this;
    EventBus.$on(SHOW_FILE, ({ file, gallery = [] }) => {
      self.$refs.fileModal.show(file, gallery);
    });

    // ACHTUNG Interval nicht kleiner machen. Ansonsten hohe last bei Prozesskontrollkarte
    self.$store.commit(SET_CURRENT_TIMESTAMP, DateTime.now().ts);
    this.time_inteval = setInterval(() => {
      self.$store.commit(SET_CURRENT_TIMESTAMP, DateTime.now().ts);
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.time_inteval);
  },
};
</script>

<style lang="scss">
@import "./scss/app.scss";
</style>

import router from '@/router';
import { MODULE_OBJECT_KEY } from './constants';

export default {
  install(Vue) {
    if (Vue.__moduleabilities_installed) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle, no-param-reassign
    Vue.__moduleabilities_installed = true;

    Vue.mixin({
      beforeCreate() {
        Object.defineProperty(this, MODULE_OBJECT_KEY, {});
      },
      created() {
        if (this[MODULE_OBJECT_KEY]) {
          const module = this[MODULE_OBJECT_KEY];
          if (this.$cannot('access', module)) {
            router.push({ name: 'forbidden' });
          }
        }
      },
    });
  },
};

function insertUrlParam(key, value, replace) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;

  if (replace && window.history.replaceState) {
    window.history.replaceState({ path: newurl }, '', newurl);
  } else if (window.history.pushState) {
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

function removeUrlParam(key, replace) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;

  if (replace && window.history.replaceState) {
    window.history.replaceState({ path: newurl }, '', newurl);
  } else if (window.history.pushState) {
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export default {
  insertUrlParam,
  removeUrlParam,
  handleUrlPropertyChange(key, value, replace = false) {
    if (value) {
      insertUrlParam(key, value, replace);
    } else {
      removeUrlParam(key, replace);
    }
  },
  getStringParameter(route, name) {
    return route.query[name] || null;
  },
  getIntParameter(route, name) {
    const param = route.query[name];
    if (!param) return null;
    return parseInt(param, 10) || null;
  },
  getBooleanParameter(route, name, nullAsFalse = false, defaultValue = null) {
    const param = route.query[name];
    if (param === null || param === undefined) {
      if (defaultValue) return defaultValue;
      if (nullAsFalse) return false;
      return null;
    }
    return JSON.parse(param);
  },
  getStringArrayParameter(route, name, separator = ',') {
    return route.query[name]?.split(separator) || [];
  },
};

export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        $env() {
          return {
            serverUrl: process.env.VUE_APP_SERVER_URL,
            showLoginImage: process.env.VUE_APP_SHOW_LOGIN_IMAGE === 'true',
            isMobile: options ? options.isMobile : undefined,
            isTestsystem: process.env.NODE_ENV !== 'production' || window.location.port === '44237',
          };
        },
      },
    });
  },
};

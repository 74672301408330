import api from '@a/config';

const path = 'v1/werkzeug/settings/historie_kommentar_muster';

export default {
  get: (page, size, sort = null, desc = false, suche = null, historietyp = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, historietyp,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  create: (name, text, historietypIds) => api.post(path, { name, text, historietypIds }),
  // eslint-disable-next-line camelcase
  update: (id, name, text, historietyp_ids) => api.put(`${path}/${id}`, { name, text, historietyp_ids }),
  delete: (id) => api.delete(`${path}/${id}`),
};

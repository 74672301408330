<template>
  <div id="privat-layout">
    <router-view v-if="abilitiesLoaded" />
    <new-app-version-modal ref="newAppVersionModal"></new-app-version-modal>
    <!-- <b-button variant="primary" @click="location.reload()"> </b-button> -->
  </div>
</template>

<script>
import store from '@st/index';
import { defineAbilities } from '@/config/ability';
import {
  LOAD_ABTEILUNGEN,
  LOAD_DOKUMENTTYPEN,
  LOAD_USERS,
} from '@st/actions.type';
import { SESSION } from '@/store/modules.type';
import {
  CONNECT_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
} from '@/store/session/actions.type';

import EventBus, { SHOW_NEW_APP_VERSION } from '@/event-bus';

import NewAppVersionModal from '@/components/NewAppVersionModal.vue';

export default {
  name: 'PrivateLayout',
  components: {
    NewAppVersionModal,
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters['session/isLoggedIn']) {
      next('/login');
    } else {
      next();
    }
  },
  data() {
    return {
      abilitiesLoaded: false,
    };
  },
  async created() {
    const { user } = this.$store.state.session;
    const abilities = defineAbilities(user);
    const self = this;

    this.$ability.update(abilities);
    this.abilitiesLoaded = true;

    this.$store.dispatch(`${SESSION}/${CONNECT_WEBSOCKET}`);

    window.onbeforeunload = () => {
      self.$store.dispatch(`${SESSION}/${DISCONNECT_WEBSOCKET}`);
    };

    await this.$store.dispatch(LOAD_ABTEILUNGEN);
    await this.$store.dispatch(LOAD_DOKUMENTTYPEN);
    await this.$store.dispatch(LOAD_USERS);
  },
  mounted() {
    const self = this;
    EventBus.$on(SHOW_NEW_APP_VERSION, () => {
      self.$refs.newAppVersionModal.show();
    });
  },
  beforeDestroy() {
    const self = this;
    EventBus.$off(SHOW_NEW_APP_VERSION, () => {
      self.$refs.newAppVersionModal.show();
    });
  },
  methods: {
    updateApp() {
      window.location.reload();
    },
  },
};
</script>

<style></style>

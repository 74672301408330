export const SET_MESSMITTELSTATI = 'set_messmittelstati';
export const SET_MESSMITTELSTATI_LOADED = 'set_messmittelstati_loaded';

export const SET_MESSMITTELTYPEN = 'set_messmitteltypen';
export const ADD_MESSMITTELTYP = 'add_messmitteltyp';
export const SET_MESSMITTELTYPEN_LOADED = 'set_messmitteltypen_loaded';

export const SET_MESSMITTELGRUPPEN = 'set_messmittelgruppen';
export const SET_MESSMITTELGRUPPEN_LOADED = 'set_messmittelruppen_loaded';

export const SET_MESSVERFAHREN = 'set_messverfahren';
export const SET_MESSVERFAHREN_LOADED = 'set_messverfahren_loaded';

export const SET_MESSBEDINGUNGEN = 'set_messbedingugnen';
export const SET_MESSBEDINGUNGEN_LOADED = 'set_messbedingugnen_loaded';

export const SET_PREUFVORSCHRIFTEN = 'set_pruefvorschriften';
export const SET_PREUFVORSCHRIFTEN_LOADED = 'set_pruefvorschriften_loaded';

export const SET_STANDORTE = 'set_standorte';
export const SET_STANDORTE_LOADED = 'set_standorte_loaded';

export const SET_MESSMITTEL_KATEGORIEN = 'set_messmittel_kategorien';
export const SET_MESSMITTEL_KATEGORIEN_LOADED = 'set_messmittel_kategorien_loaded';

export const SET_ANZAHL_MESSMITTEL = 'set_anzahl_messmittel';
export const SET_ANZAHL_MESSMITTEL_EXTERN = 'set_anzahl_messmittel_extern';

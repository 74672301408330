import api from '@a/config';

import typ from './typ';

const path = 'v1/werkzeug/historie';

export default {
  get: (werkzeug, typParam, types, createdFrom, eigene, reparaturliste, angebotserstellung, von, bis, page, size, sort = null, desc = false) => api.get(path, {
    params: {
      werkzeug, typ: typParam, types, created_from: createdFrom, eigene, reparaturliste, angebotserstellung, von, bis, page, size, sort, desc,
    },
  }),
  reparaturlisteVertriebAbarbeiten: (id) => api.patch(`${path}/${id}/reparaturliste_vertrieb_abarbeiten`),
  angebotserstellungAbarbeiten: (id, angebot_abgeben = true) => api.patch(`${path}/${id}/angebotserstellung_abarbeiten`, { angebot_abgeben }),
  typ,
};

import api from '../config';

const path = 'eingangsrechnung';

export default {
  get: (page = 0, size = 20, sort = null, desc = null, lieferant = null, rechnungsnummer = null, bezahlt = null, jahr = null) => api.get(
    `${path}`,
    {
      params: {
        page,
        size,
        sort,
        desc,
        lieferant,
        rechnungsnummer,
        bezahlt,
        jahr,
      },
    },
  ),
  getUnzugeordnet: () => api.get(`${path}/unzugeordnet`),
  getFromAbteilung: (abteilungId) => api.get(`${path}/abteilung/${abteilungId}`),
  getFreigegeben: () => api.get(`${path}/freigegeben`),
  getAbgelehnt: (page, perPage) => api.get(`${path}/abgelehnt`, { params: { page, per_page: perPage } }),
  getExportiert: (page, perPage) => api.get(`${path}/exportiert`, { params: { page, per_page: perPage } }),
  getGeloescht: (page, perPage) => api.get(`${path}/geloescht`, { params: { page, per_page: perPage } }),
  getById: (id) => api.get(`${path}/${id}`),
  put: (id, eingangsrechnung, skip_pdf = false) => api.put(`${path}/${id}`, eingangsrechnung, { params: { skip_pdf } }),
  delete: (id) => api.delete(`${path}/${id}`),
  zuordnen: (id, abteilung) => api.patch(`${path}/${id}`, { abteilung }),
  freigeben: (id) => api.patch(`${path}/freigeben/${id}`),
  wiederherstellen: (id) => api.patch(`${path}/wiederherstellen/${id}`),
  ablehnen: (id, grund) => api.patch(`${path}/ablehnen/${id}`, null, { params: { grund } }),
  ablehnenStornieren: (id) => api.delete(`${path}/ablehnen/${id}`),
  toggleBezahlt: (id) => api.patch(`${path}/bezahlt/${id}`),
  statistik: () => api.get(`${path}/statistik`),
  export: () => api.get(`${path}/export`),
};

/* eslint-disable no-unused-vars */
import numeral from 'numeral';

import 'numeral/locales/de';

numeral.register('locale', 'de_own', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(_number) {
    return '.';
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale('de_own');

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $formatAmount(val) {
          return numeral(val).format('0,0.00');
        },
        $formatPrice(val) {
          return numeral(val).format('0,0.00');
        },
      },
    });
  },
};

import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $loader(el) {
          if (this.$loading) {
            const loader = this.$loading.show({
              // Optional parameters
              container: el,
            });
            return loader;
          }
          return null;
        },
      },
    });
  },
};

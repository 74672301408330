import url from '@u/url';
import _ from 'lodash';

import localstorage from '@/services/localstorage';
import { SESSION } from '@st/modules.type';
import { GET_USER_ID } from '@st/session/getters.type';

const standardPage = 1;
const standardPageSize = 20;

const PAGE_NAME = 'page';
const SIZE_NAME = 'size';
const SORT_BY_NAME = 'sort_by';
const SORT_DESC_NAME = 'sort_desc';
const ADDITIONAL_FIELDS_NAME = 'additional_fields';

function getSelectedAdditionalFields(userId, route, pageVariablePrefix) {
  const persistedFields = localstorage.lists.getAdditionalFieldsKey(userId, route.name);
  const urlFields = route.query[(pageVariablePrefix || '') + ADDITIONAL_FIELDS_NAME]?.split(',') || [];
  const fields = _.chain(persistedFields).concat(urlFields).uniq().value();
  return fields;
}

export default {
  props: {
    pageVariablePrefix: {
      type: String,
      default: '',
    },
    persistParams: {
      type: Boolean,
      default: true,
    },
    additionalFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: (this.$route.query[(this.pageVariablePrefix || '') + PAGE_NAME] ? parseInt(this.$route.query[(this.pageVariablePrefix || '') + PAGE_NAME], 10) : null) || standardPage,
      size: (
        this.$route.query[(this.pageVariablePrefix || '') + SIZE_NAME]
          ? parseInt(this.$route.query[(this.pageVariablePrefix || '') + SIZE_NAME], 10)
          : null
      ) || (localstorage.lists.getPerPage(this.pageVariablePrefix || '') || standardPageSize),
      rows: -1,
      loaded: false,
      sort: {
        field: this.$route.query[(this.pageVariablePrefix || '') + SORT_BY_NAME] ? this.$route.query[(this.pageVariablePrefix || '') + SORT_BY_NAME] : this.standardSortField,
        desc: this.$route.query[(this.pageVariablePrefix || '') + SORT_DESC_NAME] ? JSON.parse(this.$route.query[(this.pageVariablePrefix || '') + SORT_DESC_NAME]) : false,
      },
      selectedAdditionalFields: getSelectedAdditionalFields(this.$store.getters[`${SESSION}/${GET_USER_ID}`], this.$route, this.pageVariablePrefix),
      possibleFields: [],
      numberOfElements: -1,
    };
  },
  computed: {
    standardSortField() {
      return null;
    },
    standardPageSize() {
      return standardPageSize;
    },
    $pageVariable() {
      return (this.pageVariablePrefix || '') + PAGE_NAME;
    },
    $sizeVariable() {
      return (this.pageVariablePrefix || '') + SIZE_NAME;
    },
    $sortByVariable() {
      return (this.pageVariablePrefix || '') + SORT_BY_NAME;
    },
    $sortDescVariable() {
      return (this.pageVariablePrefix || '') + SORT_DESC_NAME;
    },
    $selectedAdditionalFieldsVariable() {
      return (this.pageVariablePrefix || '') + ADDITIONAL_FIELDS_NAME;
    },
    possibleAdditionalFields() {
      return _.differenceBy(
        this.possibleFields,
        this.additionalFields,
        'key',
      ).map((e) => ({
        text: e.label,
        value: e.key,
      }));
    },
    selectedAdditionalFieldObjects() {
      return this.selectedAdditionalFields
        .map((e) => this.possibleFields.find((_e) => _e.key === e))
        .filter((e) => e !== null && e !== undefined);
    },
    $hasPossibleFields() {
      return this.possibleFields && this.possibleFields.length > 0;
    },
    itemIndex() {
      return ((this.page - 1) * this.size) + this.numberOfElements;
    },
  },
  watch: {
    page(val) {
      if (this.persistParams) { url.handleUrlPropertyChange(this.$pageVariable, val, true); }
    },
    size(val) {
      if (this.persistParams) {
        url.handleUrlPropertyChange(this.$sizeVariable, val, true);
        localstorage.lists.setPerPage(this.pageVariablePrefix, val);
      }
    },
    'sort.field': function (val) {
      if (this.persistParams) { url.handleUrlPropertyChange(this.$sortByVariable, val, true); }
    },
    'sort.desc': function (val) {
      if (this.persistParams) { url.handleUrlPropertyChange(this.$sortDescVariable, val, true); }
    },

    selectedAdditionalFields(val) {
      if (this.persistParams) {
        const variableValue = val.length > 0 ? _.join(val, ',') : null;
        url.handleUrlPropertyChange(this.$selectedAdditionalFieldsVariable, variableValue, true);
        localstorage.lists.setAdditionalFields(this.$currentUserId, this.$route.name, val || []);
      }
    },
  },
  methods: {
    $applyFilter(table) {
      this.page = 1;
      this.$refs[table || 'itemsTable']?.refresh();
    },
    $setPaginationFields(vm, responseData) {
      vm.$set(vm, 'rows', responseData.totalElements);
      vm.$set(vm, 'numberOfElements', responseData.numberOfElements);
    },

  },
};

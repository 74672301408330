import BatixIndustrialUIFramework from '@bx/batix-industrial-ui-framework';
import Loading from 'vue-loading-overlay';
import VueMeta from 'vue-meta';
import VueShortKey from 'vue-shortkey';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import SignaturePad from 'vue-signature-pad';
import {
  isMobile, isAndroid, isWinPhone, isIOS, isTablet,
} from 'mobile-device-detect';
import moduleabilities from './moduleabilities';
import files from './files';
import user from './user';
import Permission from './permission';
import Notificationhelper from './notificationhelper';
import environment from './environment';
import Numberformatter from './numberformatter';
import Loadingoverlay from './loadingoverlay';

/**
 * Setup Plugins for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
// eslint-disable-next-line import/prefer-default-export
export function setupPlugins(instance) {
  instance.use(BatixIndustrialUIFramework);

  instance.use(VueMeta, { refreshOnceOnNavigation: true });

  instance.use(VueShortKey);

  instance.use(Loading);

  instance.use(Loadingoverlay);

  instance.use(Numberformatter);

  instance.use(environment, { isMobile: isMobile || isAndroid || isWinPhone || isIOS || isTablet });

  instance.use(Notifications);

  instance.use(Notificationhelper);

  instance.use(Vuelidate);

  instance.use(Permission);

  instance.use(VueI18n);

  instance.use(abilitiesPlugin, new Ability([]));

  instance.use(moduleabilities);

  instance.use(SignaturePad);

  instance.use(files);
  instance.use(user);
}

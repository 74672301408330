/* eslint-disable no-unused-vars */
import 'babel-polyfill';
import './extensions/console-prototype-extension';

import Vue from 'vue';
import store from '@st';
import VueI18n from 'vue-i18n';
import { LOAD_USER } from '@st/session/actions.type';

import { setupGlobalComponents } from '@c/setup';
import { setupPlugins } from '@p/setup';

import dateTimeFormats from '@/i18n/date';
import numberFormats from '@/i18n/number';
import messages from '@/i18n/messages';

import { Settings } from 'luxon';
import router from './router';
import App from './App.vue';

Settings.defaultZoneName = 'Europe/Berlin';

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true;
}

setupPlugins(Vue);

setupGlobalComponents(Vue);

const defaultLanguage = 'de';

const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  dateTimeFormats,
  numberFormats,
  messages,
});

const startVueApp = () => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
};

store.dispatch(`session/${LOAD_USER}`).then(() => {
  startVueApp();
}).catch((e) => {
  startVueApp();
  const { pending } = router.history;
  router.replace({ name: 'login', query: { redir: pending?.query?.redir || (pending?.name !== 'session-expired' ? router.history.pending?.path : null) } });
});

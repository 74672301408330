import {
  formatDateFromMillis, formatDateTimeFromMillis, formatMonthFromMillis, formatTimeFromMillis, weekday,
} from '@/filter/date';

export default {
  methods: {
    formatDateFromMillis(value) {
      return formatDateFromMillis(value);
    },
    formatDateTimeFromMillis(value) {
      return formatDateTimeFromMillis(value);
    },
    formatTimeFromMillis(value) {
      return formatTimeFromMillis(value);
    },
    formatMonthFromMillis(value) {
      return formatMonthFromMillis(value);
    },
    weekday(value) {
      return weekday(value);
    },
  },
};

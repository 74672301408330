import werkzeugListeFields from '@/config/werkzeug/werkzeugListeFields';
import werkzeugstatus from '@/static/werkzeug/werkzeugstatus';
import werkzeugstatus2 from '@/static/werkzeug/werkzeugstatus2';

export default [
  {
    path: 'werkzeugverwaltung',
    component: () => import('@/layouts/werkzeugverwaltung/Werkzeugverwaltung.vue'),
    children: [
      {
        name: 'werkzeugverwaltung',
        path: '',
        component: () => import('@/views/werkzeugverwaltung/WerkzeugverwaltungStartseite.vue'),
      },
      {
        name: 'werkzeugliste',
        path: 'werkzeug',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: {
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
        },
      },
      {
        name: 'kuerzlichabgeschlossenewerkzeuge',
        path: 'werkzeug/kuerzlich_abgeschlossen',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/WerkzeuglisteKuerzlichAbgeschlossen.vue'),
      },
      {
        name: 'werkzeuge.angebotserstellung',
        path: 'werkzeug/angebotserstellung',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/WerkzeuglisteAngebotserstellung.vue'),
      },
      {
        name: 'werkzeugliste.typ',
        path: 'werkzeug/typ/:werkzeugtyp',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: (route) => ({
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          werkzeugtyp: route.params.werkzeugtyp,
        }),
      },
      {
        name: 'werkzeugliste.zum_kunden_zurueck',
        path: 'werkzeug/status2/zum_kunden_zurueck',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: () => ({
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          status2: werkzeugstatus2.zum_kunde_zurück,
        }),
      },
      {
        name: 'werkzeugliste.abmusterung',
        path: 'werkzeug/status2/abmusterung',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: () => ({
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          status2: werkzeugstatus2.abmusterung,
        }),
      },
      {
        name: 'werkzeugliste.produktion_extern',
        path: 'werkzeug/status2/produktion_extern',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: () => ({
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          status2: werkzeugstatus2.produktion_extern,
        }),
      },
      {
        name: 'werkzeugliste.nicht_fur_produktions_zugelassen',
        path: 'werkzeug/status2/nicht_fur_produktions_zugelassen',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: () => ({
          title: 'Nicht für Produktion zugelassen',
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          status: werkzeugstatus.nicht_einsatzbereit,
        }),
      },
      {
        name: 'werkzeughistorieliste.meine_historie',
        path: 'historie/meine-historie',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/HistorieListe.vue'),
        props: () => ({
          eigene: true,
          title: 'Meine Journaleinträge',
        }),
      },
      {
        name: 'werkzeugliste.status2',
        path: 'werkzeug/typ/:status2',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
        props: (route) => ({
          canAdd: true,
          additionalFields: werkzeugListeFields.stammliste,
          status2: route.params.status2,
        }),
      },
      {
        name: 'werkzeug.import',
        path: 'werkzeug/import',
        component: () => import('@v/werkzeugverwaltung/werkzeug/import/Index.vue'),
      },
      {
        name: 'werkzeug.neu.tab',
        path: 'werkzeug/neu/:primarytab/:tab',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: (route) => ({
          id: null,
          edit: true,
          tab: route.params.tab,
          primarytab: route.params.primarytab,
        }),
      },
      {
        name: 'werkzeug.neu',
        path: 'werkzeug/neu',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: {
          id: null,
          edit: true,
        },
      },
      // {
      //   name: "werkzeug.neu.tab",
      //   path: "werkzeug/neu/:primarytab",
      //   component: () => import("@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue"),
      //   props: route => {
      //     return {
      //       id: null,
      //       edit: true,
      //       tab: null,
      //       primarytab: route.params.primarytab
      //     }
      //   }
      // },

      {
        name: 'werkzeug.id',
        path: 'werkzeug/:id',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: false,
        }),
      },
      {
        name: 'werkzeug.id.edit',
        path: 'werkzeug/:id/edit',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
        }),
      },
      {
        name: 'werkzeug.id.tab',
        path: 'werkzeug/:id/:primarytab/:tab',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: (route) => ({

          id: route.params.id,
          edit: false,
          tab: route.params.tab,
          primarytab: route.params.primarytab,
        }),
      },
      {
        name: 'werkzeug.id.edit.tab',
        path: 'werkzeug/:id/edit/:primarytab/:tab',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeug.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
          tab: route.params.tab,
          primarytab: route.params.primarytab,
        }),
      },
      {
        name: 'werkzeughistorie.eigene',
        path: 'werkzeughistorie/eigene',
        component: () => import('@/views/werkzeugverwaltung/werkzeug/HistorieListe.vue'),
        props: {
          title: 'Meine Journalaktivitäten',
          eigene: true,
        },
      },
      {
        path: 'werkzeugbau',
        component: () => import('@l/werkzeugverwaltung/Werkzeugbau.vue'),
        children: [
          {
            path: '',
            name: 'werkzeugbau.liste',
            component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
            props: {
              title: 'Werkzeugbau',
              additionalFields: werkzeugListeFields.werkzeugbau,
              // moduleScope: 'manage_werkzeugbau',
              tab: 'werkzeugbau',
              werkzeugbau: true,
            },
          },
          {
            path: 'status/:status2',
            name: 'werkzeugbau.liste.status',
            component: () => import('@/views/werkzeugverwaltung/werkzeug/Werkzeugliste.vue'),
            props: (route) => ({
              title: 'Werkzeugbau',
              status2: route.params.status2,
              additionalFields: werkzeugListeFields.werkzeugbau,
              // moduleScope: 'manage_werkzeugbau',
              tab: 'werkzeugbau',
              werkzeugbau: null,
            }),
          },
          {
            path: ':id',
            name: 'werkzeugbau.detail',
            component: () => import('@/views/werkzeugverwaltung/werkzeug/werkzeugbau/Index.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'eingangspruefungen',
        name: 'werkzeug.eingangspruefungen',
        component: () => import('@v/werkzeugverwaltung/werkzeug/eingangspruefung/Eingangspruefungen.vue'),
        props: true,
      },
      {
        path: 'eingangspruefung',
        name: 'werkzeug.eingangspruefung',
        component: () => import('@v/werkzeugverwaltung/werkzeug/eingangspruefung/Eingangspruefung.vue'),
      },
      {
        path: 'eingangspruefung/werkzeug/:werkzeug',
        name: 'werkzeug.eingangspruefung.werkzeug',
        component: () => import('@v/werkzeugverwaltung/werkzeug/eingangspruefung/Eingangspruefung.vue'),
        props: true,
      },
      {
        path: 'eingangspruefung/:id',
        name: 'werkzeug.eingangspruefung.id',
        component: () => import('@v/werkzeugverwaltung/werkzeug/eingangspruefung/Eingangspruefung.vue'),
        props: true,
      },
      {
        path: 'settings',
        component: () => import('@/layouts/werkzeugverwaltung/Settings.vue'),
        children: [
          {
            path: 'historiekommentarmuster',
            name: 'werkzeug.settings.historiekommentarmuster',
            component: () => import('@v/werkzeugverwaltung/werkzeug/settings/HistorieKommentarMuster/List.vue'),
          },
          {
            path: 'historiekommentarmuster/neu',
            name: 'werkzeug.settings.historiekommentarmuster.neu',
            component: () => import('@v/werkzeugverwaltung/werkzeug/settings/HistorieKommentarMuster/Index.vue'),
          },
          {
            path: 'historiekommentarmuster/:id',
            name: 'werkzeug.settings.historiekommentarmuster.id',
            component: () => import('@v/werkzeugverwaltung/werkzeug/settings/HistorieKommentarMuster/Index.vue'),
            props: true,
          },
        ],
      },
    ],
  },
];

import api from '@a/config';

const path = 'v1/prozesskontrollkarte/fertigungsauftrag';

export default {
  getById: (id) => api.get(`${path}/${id}`),
  get: (page, size, sort = null, desc = false, suche = null, suche_reason = null, abgeschlossen = null, werkzeug = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, suche_reason, abgeschlossen, werkzeug,
    },
  }),
  getFromAuftrag: (auftrag, position) => api.get(`${path}/${auftrag}/${position}`),
  create: (prozesskontrollkarte) => api.post(path, prozesskontrollkarte),
  update: (id, prozesskontrollkarte) => api.put(`${path}/${id}`, prozesskontrollkarte),
  abschliessen: (id, reason) => api.patch(`${path}/${id}/abschliessen`, { reason }),
  reopen: (id) => api.patch(`${path}/${id}/reopen`),
  patch: (id, changes) => api.patch(`${path}/${id}`, changes),
  export: () => api.get(`${path}/export`),
};

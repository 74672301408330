import { DateTime } from 'luxon';

export function formatDateFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).toFormat('dd.MM.yyyy');
}

export function millisFromDate(value) {
  if (!value) return null;
  return value.toMillis();
}

export function formatDateTimeFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).toFormat('dd.MM.yyyy HH:mm');
}

export function formatDateTimewithWeekdayFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).toFormat('EEE dd.MM.yyyy HH:mm', { locale: 'de' });
}

export function formatTimeFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).toFormat('HH:mm');
}

export function formatWeekDayFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).setLocale('de').toFormat('EEE');
}

export function weekday(value) {
  if (value === null || value === undefined || !value) return '';
  if (typeof (value) === 'number') {
    return formatWeekDayFromMillis(value);
  } if (typeof (value) === 'object') {
    return value.setLocale('de').toFormat('EEE');
  }
  return '';
}

export function formatMonthFromMillis(value) {
  if (!value || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return DateTime.fromMillis(value).setLocale('de').toFormat('MMMM yyyy');
}

import api from '@a/config';

const path = 'v1/prozesskontrollkarte/pruefmerkmalvorlage';

export default {
  getById: (id) => api.get(`${path}/${id}`),
  get: (page, size, sort = null, desc = false, suche = null, fachbereich = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, fachbereich,
    },
  }),
  create: (pruefmerkmalvorlage) => api.post(path, pruefmerkmalvorlage),
  delete: (id) => api.delete(`${path}/${id}`),
};

import api from '@a/config';

const path = 'v1/infra/artikel/stueckliste';

export default {
  get: (artikelnummer) => api.get(path, {
    params: {
      artikelnummer,
    },
  }),
};

export default [
  {
    path: 'user',
    name: 'userlayout',
    component: () => import('@l/UserLayout.vue'),
    children: [
      {
        path: 'password',
        name: 'change-password',
        component: () => import('@v/user/ChangePassword.vue'),
      },
    ],
  },
];

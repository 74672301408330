import api from '@a/index';

import _ from 'lodash';
import { LOAD_ABMUSTERUNGGRUENDE, LOAD_ABMUSTERUNGABTEILUNGEN, LOAD_STATISTIK } from './actions.type';
import {
  SET_ABMUSTERUNGGRUENDE,
  SET_ABMUSTERUNGABTEILUNGEN,
  SET_ABMUSTERUNGGRUENDE_LOADED,
  SET_ABMUSTERUNGABTEILUNGEN_LOADED,
  SET_STATISTIK_ABTEILUNGEN,
} from './mutations.type';

export default {
  namespaced: true,
  state: {
    abteilungen: [],
    _abteilungenloaded: false,
    gruende: [],
    _gruendeloaded: false,
    statistik: {
      abteilungen: {},
    },
  },
  mutations: {
    [SET_ABMUSTERUNGABTEILUNGEN](state, val) {
      state.abteilungen = val;
    },
    [SET_ABMUSTERUNGABTEILUNGEN_LOADED](state, val) {
      state._abteilungenloaded = val;
    },
    [SET_ABMUSTERUNGGRUENDE](state, val) {
      state.gruende = val;
    },
    [SET_ABMUSTERUNGGRUENDE_LOADED](state, val) {
      state._gruendeloaded = val;
    },
    [SET_STATISTIK_ABTEILUNGEN](state, val) {
      state.statistik.abteilungen = val;
    },
  },
  actions: {
    async [LOAD_ABMUSTERUNGABTEILUNGEN]({ commit, state }) {
      if (!state._abteilungenloaded) {
        const response = await api.v1.abmusterung.abteilung.get();
        commit(SET_ABMUSTERUNGABTEILUNGEN, response.data);
        commit(SET_ABMUSTERUNGABTEILUNGEN_LOADED, true);
      }
    },
    async [LOAD_ABMUSTERUNGGRUENDE]({ commit, state }) {
      if (!state._gruendeloaded) {
        const response = await api.v1.abmusterung.grund.get();
        commit(SET_ABMUSTERUNGGRUENDE, response.data);
        commit(SET_ABMUSTERUNGGRUENDE_LOADED, true);
      }
    },
    async [LOAD_STATISTIK]({ commit }) {
      const response = await api.v1.abmusterung.getStatistik();

      const abteilungen = _.chain(response.data)
        .groupBy((e) => e.abteilung_id)
        .toPairs()
        .map(([k, v]) => [k,
          _.chain(v).groupBy((f) => f.status_id).toPairs().map(([l, n]) => [l, n[0]])
            .fromPairs()
            .value(),
        ])
        .fromPairs()
        .value();

      commit(SET_STATISTIK_ABTEILUNGEN, abteilungen);
    },
  },
  modules: {
  },
  getters: {

  },
};

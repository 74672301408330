export default [
  {
    path: '/export',
    name: 'export',
    component: () => import('@v/export/Index.vue'),
    children: [
      {
        path: 'addison',
        name: 'export.addison.liste',
        component: () => import('@v/export/addison/Liste.vue'),
      },
    ],
  },
];

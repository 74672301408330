import api from '@a/config';

const path = 'v1/prozesskontrollkarte/fertigungsauftrag/schicht';

export default {
  getById: (id) => api.get(`${path}/${id}`),
  create: (schicht) => api.post(path, schicht),
  update: (id, schicht) => {
    const upd = {
      ...schicht,
      pruefungen: schicht.pruefungen ? schicht.pruefungen.map((p) => ({
        id: p.id,
        pruefung_nio: p.pruefung_nio,
        pruefung_value: p.pruefung_value,
      })) : null,
    };
    return api.put(`${path}/${id}`, upd);
  },
  patch: (id, schicht) => {
    const upd = {
      ...schicht,
      pruefungen: schicht.pruefungen ? schicht.pruefungen.map((p) => ({
        id: p.id,
        pruefung_nio: p.pruefung_nio,
        pruefung_value: p.pruefung_value,
      })) : null,
    };
    return api.patch(`${path}/${id}`, upd);
  },
};

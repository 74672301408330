import prozesskontrollkarteStatus from '@/static/prozesskontrollkarte/prozesskontrollkarteStatus';

export default [
  {
    path: 'prozesskontrollkarte',
    component: () => import('@/layouts/prozesskontrollkarte/Prozesskontrollkarte.vue'),
    children: [
      {
        name: 'prozesskontrollkarte',
        path: '',
        component: () => import('@v/prozesskontrollkarte/ProzesskontrollkarteStartseite.vue'),
      },
      {
        name: 'fertigungsauftrag',
        path: 'fertigungsauftrag',
        component: () => import('@/layouts/prozesskontrollkarte/Fertigungsauftrag.vue'),
        children: [
          {
            name: 'fertigungsauftrag.liste',
            path: 'liste',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/FertigungsauftragListe.vue'),
          },
          {
            name: 'fertigungsauftrag.prozesskontrollkarte.liste',
            path: 'prozesskontrollkarte',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/prozesskontrollkarte/Prozesskontrollkartenliste.vue'),
          },
          {
            name: 'fertigungsauftrag.prozesskontrollkarte.liste.abgeschlossen',
            path: 'prozesskontrollkarte/abgeschlossen',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/prozesskontrollkarte/Prozesskontrollkartenliste.vue'),
            props: {
              abgeschlossen: true,
            },
          },
          {
            name: 'fertigungsauftrag.prozesskontrollkarte.liste.aktuell',
            path: 'prozesskontrollkarte/aktuell',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/prozesskontrollkarte/Prozesskontrollkartenliste.vue'),
            props: {
              abgeschlossen: false,
            },
          },
          {
            name: 'fertigungsauftrag.prozesskontrollkarte.id',
            path: 'prozesskontrollkarte/:id',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/prozesskontrollkarte/Prozesskontrollkarte.vue'),
            props: true,
          },
          {
            name: 'fertigungsauftrag.prozesskontrollkarte.id.edit',
            path: 'prozesskontrollkarte/:id/edit',
            component: () => import('@v/prozesskontrollkarte/fertigungsuaftrag/prozesskontrollkarte/Prozesskontrollkarte.vue'),
            props: (route) => ({
              id: route.params.id,
              edit: true,
            }),
          },
        ],
      },
      {
        path: 'admin',
        component: () => import('@/layouts/prozesskontrollkarte/ProzesskontrollkarteAdmin.vue'),
        children: [
          {
            name: 'prozesskontrollkarteliste.admin',
            path: 'list',
            component: () => import('@v/prozesskontrollkarte/admin/Prozesskontrollkartenliste.vue'),
          },
          {
            name: 'prozesskontrollkarteliste.admin.entwuerfe',
            path: 'list/entwuerfe',
            component: () => import('@v/prozesskontrollkarte/admin/Prozesskontrollkartenliste.vue'),
            props: {
              title: 'Prozesskontrollkarten - Entwürfe',
              status: prozesskontrollkarteStatus.entwurf,
            },
          },
          {
            path: 'prozesskontrollkarte/neu',
            name: 'prozesskontrollkarte.admin.neu',
            component: () => import('@v/prozesskontrollkarte/admin/Prozesskontrollkarte.vue'),
            props: {
              id: null,
              edit: true,
            },
          },
          {
            path: 'prozesskontrollkarte/:id',
            name: 'prozesskontrollkarte.admin.id',
            component: () => import('@v/prozesskontrollkarte/admin/Prozesskontrollkarte.vue'),
            props: true,
          },
          {
            path: 'prozesskontrollkarte/:id/edit',
            name: 'prozesskontrollkarte.admin.id.edit',
            component: () => import('@v/prozesskontrollkarte/admin/Prozesskontrollkarte.vue'),
            props: (route) => ({
              edit: true,
              id: route.params.id,
            }),
          },
          {
            path: 'fertigungsauftrag/ohne_pck',
            name: 'prozesskontrollkarte.fertigungsauftrag.ohne_pck',
            component: () => import('@v/prozesskontrollkarte/admin/FertigungsauftragListeOhnePck.vue'),
            props: true,
          },
        ],
      },
    ],
  },
];

<template>
  <div>
    <navbar :title="title" :zentrale-to="{ name: 'eingangsrechnungen' }" page="eingangsrechnung" />
    <b-container>
      <b-row class="mb-3">
        <b-col cols="6">
          <b-pagination v-if="type && type.pagination && rows >= 0" v-model="page" :total-rows="rows" :per-page="perPage"></b-pagination>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button v-if="type && type.showExport" variant="primary" :disabled="rows.length <= 0" @click="exportZip">
            <i class="ifr if-download mr-2" /> Export
          </b-button>
        </b-col>
      </b-row>

      <b-table striped :items="eingangsrechnungen" :fields="fields" head-variant="dark" details-td-class="px-0" responsive show-empty empty-text="Keine Eingangsrechnungen vorhanden">
        <template #cell(pdf)="row">
          <i class="far fa-file-pdf text-danger mr-3" />
          <span v-if="row.item.original_pdf">
            {{ row.item.original_pdf.name }}
          </span>
        </template>
        <template #cell(geloescht_am)="row">{{
          formatDateFromMillis(row.item.geloescht_am)
        }}</template>
        <template #cell(abgelehnt_am)="row">{{
          formatDateTimeFromMillis(row.item.abgelehnt_am)
        }}</template>
        <template #cell(edit)="row">
          <b-button size="sm" variant="primary" :to="{
            name: 'eingangsrechnung.detail',
            params: { id: row.item.id },
            query: { abteilung: abteilung ? abteilung.id : null },
          }">
            <i class="ifr if-pencil-short" />
          </b-button>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import url from '@u/url';
import api from '@a/index';
import listtype from '@/config/eingangsrechnung/listtypes';
import { DateTime } from 'luxon';

import dateFilter from '@/mixins/dateFilter';

export default {
  components: {},
  name: 'EingangsrechnungListe',
  mixins: [dateFilter],
  props: {
    type: {
      type: Object,
    },
  },
  metaInfo() {
    const { title } = this;
    return {
      titleTemplate: () => title,
    };
  },
  data() {
    return {
      page:
        (this.$route.query.page ? parseInt(this.$route.query.page, 10) : null) || 1,
      perPage:
        (this.$route.query.per_page
          ? parseInt(this.$route.query.per_page, 10)
          : null) || 20,
      rows: 0,
      eingangsrechnungen: [],
      loaded: false,
    };
  },
  watch: {
    page(val) {
      if (val) url.insertUrlParam('page', val);
      else url.removeUrlParam('page');
      this.ladeEingangsrechnugnen();
    },
    perPage(val) {
      url.insertUrlParam('per_page', val);
    },
  },
  computed: {
    title() {
      if (this.type && this.type.id === listtype.abteilung) return `Eingangsrechnungen ${this.abteilung?.titel}`;
      if (this.type) return this.type.title;
      return 'Eingangsrechnungen';
    },
    abteilungen() {
      return this.$store.state.abteilungen;
    },
    abteilung() {
      if (this.type && this.type.id === listtype.abteilung) {
        return this.abteilungen.find((a) => a.id === this.type.value) || null;
      }
      return null;
    },
    noItems() {
      return this.loaded && this.eingangsrechnungen.length === 0;
    },
    noPagination() {
      return this.exportiert === false;
    },
    fields() {
      const fields = [
        {
          key: 'vorgangsnummer',
          sortable: this.type && !this.type.pagination,
          label: 'Vorgangsnummer',
          tdClass: 'py-1',
        },
        {
          key: 'pdf',
          sortable: false,
          label: 'PDF',
          tdClass: 'py-1',
        },
      ];

      if (this.type && this.type.id === listtype.papierkorb) {
        fields.push({
          key: 'geloescht_am',
          label: 'Gelöscht am',
        });
        fields.push({
          key: 'geloescht_von',
          label: 'Gelöscht Von',
          formatter: (value) => value?.full_name,
        });
      } else if (this.type && this.type.id === listtype.abgelehnt) {
        fields.push({
          key: 'abgelehnt_am',
          label: 'Abgelehnt am',
        });
        fields.push({
          key: 'abgelehnt_von',
          label: 'Abgelehnt Von',
          formatter: (value) => value?.full_name,
        });
      } else {
        fields.push({
          key: 'zugeordnete_abteilung.titel',
          sortable:
            this.type
            && !this.type.pagination
            && !this.type.id === listtype.abteilung,
          label: 'Abteilung',
          tdClass: 'py-1',
        });
      }

      if (this.type && this.type.id === listtype.abteilung) {
        fields.push({
          key: 'freizugeben_bis',
          sortable: this.type && !this.type.pagination,
          label: 'Freizugeben bis',
          tdClass: (val) => {
            const classes = ['py-1'];
            if (val && val < DateTime.local().ts) classes.push('text-danger');
            return classes;
          },
          formatter: (val) => (val ? DateTime.fromMillis(val).toFormat('dd.MM.yyyy') : ''),
        });
      }

      if (
        this.type?.id !== listtype.papierkorb
        && this.type?.id !== listtype.abgelehnt
      ) {
        fields.push({
          key: 'kostenstelle',
          sortable: this.type && !this.type.pagination,
          label: 'Kostenstelle',
          tdClass: 'py-1',
        });
      }

      fields.push({
        key: 'edit',
        sortable: false,
        label: '',
        tdClass: 'py-1 text-right',
      });

      return fields;
    },
  },
  async created() {
    await this.ladeEingangsrechnugnen();
  },
  methods: {
    async ladeEingangsrechnugnen() {
      const loader = this.$loader();
      try {
        const response = await this.getEingangsrechnungResponse();
        this.eingangsrechnungen = response.data.data;
        this.rows = response.data.pagination.count;
      } finally {
        loader.hide();
        this.loaded = true;
      }
    },

    async getEingangsrechnungResponse() {
      if (this.type) {
        switch (this.type.id) {
          case listtype.unzugeordnet:
            return api.eingangsrechnung.getUnzugeordnet();
          case listtype.abteilung:
            return api.eingangsrechnung.getFromAbteilung(this.type.value);
          case listtype.abgelehnt:
            return api.eingangsrechnung.getAbgelehnt(
              this.page,
              this.perPage,
            );
          case listtype.freigegeben:
            return api.eingangsrechnung.getFreigegeben();
          case listtype.archiv:
            return api.eingangsrechnung.getExportiert(
              this.page,
              this.perPage,
            );
          case listtype.papierkorb:
            return api.eingangsrechnung.getGeloescht(
              this.page,
              this.perPage,
            );
          default:
            return api.eingangsrechnung.get(this.page, this.perPage);
        }
      } else {
        return api.eingangsrechnung.get(this.page, this.perPage);
      }
    },
    async exportZip() {
      window.open(
        `${this.$env.serverUrl}/api/eingangsrechnung/export`,
        '_blank',
      );
    },
  },
};
</script>

<style></style>

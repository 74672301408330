import api from '@a/config';
import fertigungsauftrag from './fertigungsauftrag';
import teilegruppe from './teilegruppe';
import artikel from './artikel';

const path = 'v1/infra';

export default {
  getArtikelnummern(suche) {
    return api.get(`${path}/erzeugnis/`, { params: { suche } });
  },
  getZeichnungsnummern(erzeugnis, suche) {
    return api.get(`${path}/zeichnungsnummer/`, { params: { erzeugnis, suche } });
  },
  fertigungsauftrag,
  teilegruppe,
  artikel,
};

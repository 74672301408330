<template>
  <b-modal size="xl" ref="modal" ok-only ok-title="Schließen" header-class="bg-cosmos" :dialog-class="{ 'file-modal': true, 'has-gallery': hasGallery }" @hide="clearFile">
    <template #modal-header>
      <div class="w-100">
        <div v-if="$env.isMobile && file && isPdf(file)" class="row d-none d-md-flex">
          <div class="col-md-3 text-left d-none d-md-block">
            <button class="btn btn-ice" title="PDF nach links drehen" v-b-tooltip.hover @click="rotateLeft">
              <i class="fas fa-undo"></i>
            </button>
            <button class="btn btn-ice ml-1" title="PDF nach rechts drehen" v-b-tooltip.hover @click="rotateRight">
              <i class="fas fa-undo mirror-horizonal"></i>
            </button>
            <button class="btn btn-ice ml-1" title="Download" v-b-tooltip.hover @click="download">
              <i class="fas fa-download"></i>
            </button>
          </div>

          <div class="text-center fs-18 font-weight-bold col-6">
            <button class="btn btn-ice mr-3" :disabled="!hasPreviousPage" @click="previousPage">
              <i class="ifr if-arrow-with-circle-left"></i>
            </button>
            <span class="text-white">Seite {{ pdfPage }} von {{ pdfPages }}</span>
            <button class="btn btn-ice ml-3" :disabled="!hasNextPage" @click="nextPage">
              <i class="ifr if-arrow-with-circle-right"></i>
            </button>
          </div>
          <div class="col-md-3 text-right d-none d-md-block">
            <b-button v-if="file" variant="ice" :href="file.url" target="_blank" class="mr-3">
              <i class="ifr if-external"></i>
            </b-button>
            <button class="btn btn-ice" @click="hide">
              <i class="ifr if-cancel"></i>
            </button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-6">
            <button class="btn btn-ice ml-1" title="Download" v-b-tooltip.hover @click="download">
              <i class="fas fa-download"></i>
            </button>
          </div>
          <div class="col-6 text-right">
            <b-button v-if="file" variant="ice" :href="file.url" target="_blank" class="mr-3">
              <i class="ifr if-external"></i>
            </b-button>
            <button class="btn btn-ice" @click="hide">
              <i class="ifr if-cancel"></i>
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-if="file && isPdf(file) && !$env.isMobile">
      <iframe :src="file.url" :page="pdfPage" width="100%" :height="heightPixel" @load="loaded = true" title="file">
      </iframe>
    </div>
    <div v-else-if="file && isPdf(file) && $env.isMobile">
      <div class="pdf-wrapper">

        <vue-pdf-embed :source="file.url" :page="pdfPage" :rotation="rotation" ref="pdf" @loaded="onPdfLoaded" @progress="(n) => (pdfProgress = n)" />

        <div v-show="loadingProgress < 1" class="progress">
          <div class="progress-bar" role="progressbar" :style="{ width: progressPercent + '%' }" :aria-valuenow="progressPercent" aria-valuemin="0" aria-valuemax="100">
            {{ loadingProgress * 100 }} %
          </div>
        </div>
        <div v-if="error">
          <b-alert variant="danger" show>
            Beim Anzeigen des PDF's ist ein Fehler aufgetreten
          </b-alert>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 col-lg-3 col-1 d-none d-md-flex"></div>
        <div class="col-3 col-md-2 col-lg-1 text-md-right text-left">
          <button class="btn btn-ice mr-3" :disabled="!hasPreviousPage" @click="previousPage">
            <i class="ifr if-arrow-with-circle-left"></i>
          </button>
        </div>
        <div class="text-center fs-18 font-weight-bold col-6 col-md-4">
          Seite {{ pdfPage }} von {{ pdfPages }}
        </div>
        <div class="col-3 col-md-2 col-lg-1 text-md-left text-right">
          <button class="btn btn-ice ml-3" :disabled="!hasNextPage" @click="nextPage">
            <i class="ifr if-arrow-with-circle-right"></i>
          </button>
        </div>
        <div class="col-md-2 col-lg-3 text-right d-none d-md-block"></div>
      </div>
    </div>
    <div v-else-if="file && isVideo(file)" class="text-center">
      <video controls autoplay width="100%">
        <source :src="file.url" class="file-modal-video" :type="file.mimeType" />
        <track kind="captions" />
      </video>
    </div>
    <div v-else-if="file && isImage(file)" class="text-center">
      <img :src="file.url" class="file-modal-image" alt="Das Bild" />
    </div>
    <div v-if="hasGallery" class="mx-3 mt-3">
      <div class="d-flex file-gallery justify-content-center">
        <b-img v-for="g in gallery" :key="g.id" thumbnail center height="100" style="max-height: 100px" :src="g.url" :alt="g.originalName" v-b-tooltip.hover="g.originalName" role="button"
          :class="{ selected: isActiveGalleryFile(g) }" @click="setGalleryImage(g)"></b-img>
      </div>
    </div>
  </b-modal>
</template>

<script>
import modal from '@m/modal';
import _ from 'lodash';
import file from '@u/file';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
  name: 'FileModal',
  mixins: [modal],
  components: { VuePdfEmbed },
  data() {
    return {
      windowHeight: 0,
      file: null,
      pdfPage: 1,
      pdfPages: 0,
      pdfProgress: { loaded: 0, total: 0 },
      loaded: false,
      open: false,
      rotation: 0,
      error: false,
      gallery: [],
      galleryActiveIndex: null,
    };
  },
  computed: {
    loadingProgress() {
      return this.pdfProgress ? this.pdfProgress.loaded / this.pdfProgress.total : 0;
    },
    progressPercent() {
      return this.loadingProgress * 100;
    },
    hasPreviousPage() {
      return this.pdfPage > 1;
    },
    hasNextPage() {
      return this.pdfPage < this.pdfPages;
    },
    heightPixel() {
      return `${this.windowHeight - 230}px`;
    },
    hasGallery() {
      return (
        this.file
        && file.isGallerySuitable(this.file)
        && this.gallery.length > 0
      );
    },
  },
  methods: {
    show(f, gallery = []) {
      this.windowHeight = window.innerHeight;
      if (!this.canShowFile(f)) {
        this.file = null;
        window.open(f.downloadUrl, '_blank');
      } else {
        this.file = f;
        this.gallery = gallery;
        this.galleryActiveIndex = _.indexOf(gallery, (e) => e.url === f.url);
        this.$refs.modal.show();
      }
    },
    setGalleryImage(f) {
      this.galleryActiveIndex = _.indexOf(
        this.gallery,
        (e) => e.url === f.url,
      );
      this.file = f;
    },
    isActiveGalleryFile(f) {
      return this.file?.url === f.url;
    },
    isGallerySuitable(f) {
      return file.isGallerySuitable(f);
    },
    isImage(f) {
      return file.isImage(f);
    },
    isVideo(f) {
      return file.isVideo(f);
    },
    isPdf(f) {
      return file.isPdf(f);
    },
    canShowFile(f) {
      return this.isImage(f) || this.isPdf(f) || this.isVideo(f);
    },
    clearFile() {
      this.file = null;
      this.gallery = [];
      this.resetPdf();
    },
    onPdfLoaded(e) {
      this.pdfPages = e._pdfInfo.numPages;
      this.loaded = true;
    },
    resetPdf() {
      this.pdfPages = 0;
      this.pdfPage = 1;
      this.loadingProgress = 0;
      this.rotation = 0;
      this.error = false;
    },
    nextPage() {
      this.pdfPage += 1;
    },
    previousPage() {
      this.pdfPage -= 1;
    },
    rotateLeft() {
      if (this.rotation === 0) this.rotation = 270;
      else this.rotation -= 90;
    },
    rotateRight() {
      if (this.rotation === 270) this.rotation = 0;
      else this.rotation += 90;
    },
    print() { },
    download() {
      window.open(this.file.downloadUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateX(50%);
  width: 50%;
}

.pdf-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: white;
}
</style>

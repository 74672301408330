export const LOAD_MESSMITTELSTATI = 'load_messmittelstati';
export const LOAD_MESSMITTELTYPEN = 'load_messmitteltypen';
export const LOAD_MESSMITTELGRUPPEN = 'load_messmittelgruppen';
export const LOAD_MESSVERFAHREN = 'load_messverfahren';
export const LOAD_MESSBEDINGUNGEN = 'load_messbedingugnen';
export const LOAD_PREUFVORSCHRIFTEN = 'load_pruefvorschriften';
export const LOAD_STANDORTE = 'load_standorte';
export const LOAD_MESSMITTEL_KATEGORIEN = 'load_messmittel_kategorien';
export const LOAD_KATEGORIEN = 'load_kategorien';
export const LOAD_ANZAHL_MESSMITTEL = 'load_anzahl_messmittel';
export const LOAD_ANZAHL_MESSMITTEL_EXTERN = 'load_anzahl_messmittel_extern';

export default {
  eingangskontrolle: '180FB3A12D2',
  reparatur: '180FB38F194',
  reparatur_ersatzteile_bestellt: '180FB39351D',
  reparatur_extern: '180FB395D54',
  werkzeugaenderung: '180FB398DB5',
  wartung: '180FB39C510',
  werkzeugumbau: '180FB39EA9F',
  in_arbeit: '18147800EF4',
  im_lager: '18170A7CBA3',
  zum_kunde_zurück: '185CF16ED22',
  abmusterung: '1874B42E995',
  produktion_extern: '1874B5D2AF9',
  angebotserstellung: '1874BA81EFB',
};

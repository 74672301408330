export default [
  {
    path: 'settings',
    name: 'settings',
    component: () => import('@/layouts/settings/SettingsLayout.vue'),
    children: [
      {
        path: 'info-display',
        name: 'settings.info-display',
        component: () => import('@/layouts/settings/display/DisplaySettingsLayout.vue'),
        children: [
          {
            path: 'unternehmensziele',
            name: 'settings.info-display.unternehmensziele',
            component: () => import('@/views/settings/display/Unternehmensziele.vue'),
          },
        ],
      },
    ],
  },
];

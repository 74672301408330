<template>
  <div>
    <navbar :title="title" :zentrale-to="{ name: 'eingangsrechnungen' }" page="eingangsrechnung" ability="rechnungseingangsbuch" />
    <b-container class="container-xxl-max">
      <bx-box>
        <bx-box variant="ice">
          <b-row>
            <b-col lg="4" xl="4" md="6">
              <b-form-group label="Bezahlt">
                <b-form-radio v-model="bezahlt_suche" :value="null" name="bezahlt_suche" inline>Alle</b-form-radio>
                <b-form-radio v-model="bezahlt_suche" :value="true" name="bezahlt_suche" inline>Bezahlt</b-form-radio>
                <b-form-radio v-model="bezahlt_suche" :value="false" name="bezahlt_suche" inline>Nicht
                  Bezahlt</b-form-radio>
              </b-form-group>
            </b-col>
            <b-col lg="3" xl="3" md="6">
              <b-form-group label="Lieferant">
                <b-input-group size="sm">
                  <b-form-input v-model="lieferant_suche" debounce="500" />
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover title="Lieferantensuche leeren" @click="lieferant_suche = null">
                      <i class="ifr if-cancel" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="3" xl="3" md="6" offset-md="6" offset-lg="0">
              <b-form-group label="Rechnungsnummer">
                <b-input-group size="sm">
                  <b-form-input v-model="rechnungsnummer_suche" debounce="500" />
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover title="Rechnungsnummersuche leeren" @click="rechnungsnummer_suche = null">
                      <i class="ifr if-cancel" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="2" xl="2" md="6" offset-md="6" offset-lg="0">
              <b-form-group label="Jahr">
                <b-input-group size="sm">
                  <b-form-select v-model="jahr" :options="jahrOptions" placeholder="Jahr wählen">
                  </b-form-select>
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover title="Jahr leeren" @click="jahr = null">
                      <i class="ifr if-cancel" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </bx-box>
      </bx-box>
      <b-row class="my-2">
        <b-col xl="3">
          <b-pagination v-if="rows >= 0" v-model="page" :total-rows="rows" :per-page="size"></b-pagination>
        </b-col>
        <b-col xl="3" offset-xl="6" class="text-right">
          <label class="mr-2">Pro Seite</label>
          <b-form-select class="d-inline-block w-auto" v-model="size" :options="[10, 20, 50, 100]"></b-form-select>
        </b-col>
      </b-row>

      <b-table ref="itemsTable" class="rechnungseingangsbuch" striped :items="itemsProvider" :fields="fields" :current-page="page" :per-page="size" head-variant="dark" details-td-class="px-0" responsive
        show-empty hover bordered outlined :sort-by="sort.field" :sort-desc="sort.desc" empty-text="Keine Eingangsrechnungen vorhanden" @row-dblclicked="(item) => goToDetail(item)">
        <template #cell(pdf)="row">
          <i class="far fa-file-pdf text-danger mr-3" />
          <span v-if="row.item.original_pdf">
            {{ row.item.original_pdf.name }}
          </span>
        </template>
        <template #cell(importiert_am)="row">{{
          formatDateFromMillis(row.item.importiert_am)
        }}</template>
        <template #cell(freizugeben_bis)="row">{{
          formatDateFromMillis(row.item.freizugeben_bis)
        }}</template>
        <template #cell(datum_rechnung)="row">{{
          formatDateFromMillis(row.item.datum_rechnung)
        }}</template>
        <template #cell(datum_skonto)="row">{{
          formatDateFromMillis(row.item.datum_skonto)
        }}</template>
        <template #cell(datum_faelligkeit)="row">{{
          formatDateFromMillis(row.item.datum_faelligkeit)
        }}</template>
        <template #cell(zugeordnete_abteilung)="row">
          <span v-if="row.item.zugeordnete_abteilung">
            <i :class="[row.item.zugeordnete_abteilung.icon]" />
            {{ row.item.zugeordnete_abteilung.titel }}
          </span>
        </template>
        <template #cell(betrag)="row">{{
          formatPriceEuro(row.item.betrag)
        }}</template>
        <template #cell(edit)="row">
          <b-button-group>
            <b-button size="sm" variant="primary" :to="{
              name: 'eingangsrechnung.detail',
              params: { id: row.item.id },
            }">
              <i class="ifr if-pencil-short" />
            </b-button>
            <b-button size="sm" :variant="row.item.bezahlt ? 'success' : 'secondary'" :disabled="!darfBezahlten" v-b-tooltip.hover.left="row.item.bezahlt
              ? 'Rechnung als unbezahlt markieren'
              : 'Rechnung als bezahlt markieren'
              " @click="toggleBezahlt(row.item.id)">
              <i class="fas fa-wallet" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import url from '@u/url';
import api from '@a/index';
import usergroup from '@/config/user/groups';
import sortableAndPageableList from '@m/sortableAndPageableList';
import { DateTime } from 'luxon';
import _ from 'lodash';
import dateFilter from '@/mixins/dateFilter';
import numeralFilter from '@/mixins/numeralFilter';

export default {
  components: {},
  name: 'Rechnungseingangsbuch',
  mixins: [sortableAndPageableList, dateFilter, numeralFilter],
  props: {
    type: {
      type: Object,
    },
  },
  metaInfo() {
    const { title } = this;
    return {
      titleTemplate: () => title,
    };
  },
  data() {
    return {
      loaded: false,
      lieferant_suche: this.$route.query.lieferant || null,
      rechnungsnummer_suche: this.$route.query.rechnungsnummer || null,
      bezahlt_suche: this.$route.query.bezahlt
        ? JSON.parse(this.$route.query.bezahlt)
        : null,
      jahr: this.$route.query.jahr
        ? parseInt(this.$route.query.jahr, 10) || null
        : null,
    };
  },
  watch: {
    lieferant_suche(val) {
      this.$applyFilter();
      url.handleUrlPropertyChange('lieferant', val, true);
    },
    rechnungsnummer_suche(val) {
      this.$applyFilter();
      url.handleUrlPropertyChange('rechnungsnummer', val, true);
    },
    bezahlt_suche(val) {
      this.$applyFilter();
      url.handleUrlPropertyChange('bezahlt', val, true);
    },
    jahr(val) {
      this.$applyFilter();
      url.handleUrlPropertyChange('jahr', val, true);
    },
  },
  computed: {
    title() {
      return 'Rechnungseingangsbuch';
    },
    abteilungen() {
      return this.$store.state.abteilungen;
    },
    darfBezahlten() {
      return this.$hasGroup(usergroup.rechnungen_bezahlen);
    },
    jahrOptions() {
      const start = DateTime.now().get('year');
      return _.range(start, start - 10);
    },
    fields() {
      const fields = [
        {
          key: 'importiert_am',
          sortable: true,
          label: 'Erfassungsdatum',
          tdClass: 'py-1 text-center',
          thClass: 'text-center align-middle erfassungsdatum-header',
          _rowVariant: (val) => {
            if (val.bezahlt) return 'success';
            if (val.abgelehnt_am !== null) {
              return 'danger';
            }
            return '';
          },
        },
        {
          key: 'lieferant',
          sortable: true,
          label: 'Lieferant',
          tdClass: 'py-1',
          thClass: 'align-middle',
          formatter: (value) => {
            if (value) {
              return `${value.name} ${value.nummer}`;
            }
            return '';
          },
        },
        {
          key: 'freizugeben_bis',
          sortable: true,
          label: 'Zurück zu BC',
          tdClass: 'py-1 text-center',
          thClass: 'align-middle text-center',
        },
        {
          key: 'betrag',
          sortable: true,
          label: 'Betrag',
          tdClass: (value) => {
            if (value < 0) {
              return 'py-1 text-right text-danger';
            }
            return 'py-1 text-right';
          },
          thClass: 'align-middle price-field',
        },
        {
          key: 'datum_rechnung',
          sortable: true,
          label: 'Rechnungsdatum',
          tdClass: 'py-1 text-center',
          thClass: 'text-center align-middle',
        },
        {
          key: 'rechnungsnummer',
          sortable: true,
          label: 'Rechnungsnummer',
          tdClass: 'py-1',
          thClass: 'align-middle',
        },
        {
          key: 'datum_faelligkeit',
          sortable: true,
          label: 'fällig',
          tdClass: 'py-1 text-center',
          thClass: 'text-center align-middle',
        },
        {
          key: 'datum_skonto',
          sortable: true,
          label: 'Skonto bis',
          tdClass: 'py-1 text-center',
          thClass: 'text-center align-middle',
        },
        {
          key: 'zugeordnete_abteilung',
          sortable: true,
          label: 'gegeben an',
          tdClass: 'py-1',
          thClass: 'align-middle',
          formatter: (val) => val?.titel || '',
        },
        {
          key: 'edit',
          sortable: false,
          label: '',
          tdClass: 'py-1 text-right',
        },
      ];

      return fields;
    },
  },

  methods: {
    async itemsProvider(ctx, callback) {
      const self = this;
      api.eingangsrechnung
        .get(
          ctx.currentPage,
          ctx.perPage,
          ctx.sortBy,
          ctx.sortDesc,
          this.lieferant_suche,
          this.rechnungsnummer_suche,
          this.bezahlt_suche,
          this.jahr,
        )
        .then((response) => {
          response.data.content.forEach((e) => {
            if (e.bezahlt) e._rowVariant = 'success';
            else if (e.abgelehnt_am !== null) {
              e._rowVariant = 'danger';
            }
          });
          this.$set(self, 'rows', response.data.totalElements);
          this.$set(self.sort, 'field', ctx.sortBy);
          this.$set(self.sort, 'desc', ctx.sortDesc);
          callback(response.data.content);
        })
        .catch((e) => {
          this.$notifyError(e);
        });
    },
    async toggleBezahlt(id) {
      const loader = this.$loader();
      try {
        await api.eingangsrechnung.toggleBezahlt(id);
        this.$refs.itemsTable.refresh();
      } catch (e) {
        this.$notifyError(e);
      } finally {
        loader.hide();
      }
    },
    goToDetail(item) {
      this.$router.push({
        name: 'eingangsrechnung.detail',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/@bx/batix-industrial-ui-framework/scss/_colors.scss";

.price-field {
  min-width: 100px;
}

.rechnungseingangsbuch {
  font-size: 0.75rem;
}
</style>

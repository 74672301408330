import user from './user';
import eingangsrechnung from './eingangsrechnung';
import abteilung from './abteilung';
import lieferant from './lieferant';
import kunde from './kunde';
import _export from './export';
import v1 from './v1';

export default {
  user,
  eingangsrechnung,
  abteilung,
  lieferant,
  kunde,
  export: _export,
  v1,
};

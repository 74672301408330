export default {
  newAppVersion: 'new_app_version',
  connectionOpened: 'connection_opened',
  registration: 'registration',
  joinRoom: 'join',
  current_uri: 'current_uri',
  leaveRoom: 'leave',
  generic: 'message',
  request_user_reload: 'request_user_reload',
};

const operations = {

};

export { operations };

import api from '@a/config';
import status from './status';
import typ from './typ';
import gruppe from './gruppe';
import ausfuehrung from './ausfuehrung';
import historie from './historie';
import settings from './settings';
import werkzeugbau from './werkzeugbau';
import eingangspruefung from './eingangspruefung';
import standort from './standort';

const path = 'v1/werkzeug';

export default {
  get: (page, size, sort = null, desc = false, suche = null, sucheKunde = null, werkzeugtyp = null, statusParam = null, status2 = null, werkzeugbauParam = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, werkzeugtyp, status: statusParam, sucheKunde, status2, werkzeugbau: werkzeugbauParam,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  getByWerkzeugnummer: (werkzeugnummer) => api.get(`${path}/by_werkzeugnummer/${werkzeugnummer}`),
  getWechselleinsaetze: (id) => api.get(`${path}/${id}/wechseleinsatz`),
  getHistorie: (id, page, size, sort = null, desc = false) => api.get(`${path}/${id}/historie`, {
    params: {
      page, size, sort, desc,
    },
  }),
  addHistorie: (id, formData) => api.post(`${path}/${id}/historie`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  changeHistorie: (werkzeugId, historieId, formData) => api.put(`${path}/${werkzeugId}/historie/${historieId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  deleteHistorie: (werkzeugId, historieId) => api.delete(`${path}/${werkzeugId}/historie/${historieId}`),
  getDokumente: (id, page, size, sort = null, desc = false, typParam = null, suche = null) => api.get(`${path}/${id}/dokument`, {
    params: {
      page, size, sort, desc, typ: typParam, suche,
    },
  }),
  create: (werkzeug) => api.post(path, werkzeug),
  update: (id, werkzeug) => api.put(`${path}/${id}`, werkzeug),
  addDocument: (id, formData) => api.post(`${path}/${id}/dokument`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  werkzeugbauFertigstellen: (id) => api.patch(`${path}/${id}/werkzeugbau_fertigstellen`),
  standort,
  status,
  typ,
  gruppe,
  ausfuehrung,
  historie,
  settings,
  werkzeugbau,
  eingangspruefung,
  importCsv: (formdata) => api.post(`${path}/import`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  werkzeugnummerExists: (werkzeugnummer, id) => api.get(`${path}/nummerexists`, { params: { werkzeugnummer, id } }),
  getArtikel: (id) => api.get(`${path}/${id}/artikel`),
  addArtikel: (id, artikel) => api.post(`${path}/${id}/artikel`, artikel),
  updateArtikel: (werkzeugId, artikelId, artikel) => api.put(`${path}/${werkzeugId}/artikel/${artikelId}`, artikel),
  removeArtikel: (werkzeugId, artikelId) => api.delete(`${path}/${werkzeugId}/artikel/${artikelId}`),
  getKuerzlichAbgeschlossen: (page, size, sort = null, desc = false) => api.get(`${path}/kuerzlich_abgeschlossen`, {
    params: {
      page, size, sort, desc,
    },
  }),
  getAnzahlKuerzlichAbgeschlossen: () => api.get(`${path}/kuerzlich_abgeschlossen/anzahl`),
  ausKuerzlichAbgeschlossenentfernen: (id) => api.delete(`${path}/kuerzlich_abgeschlossen/${id}`),
  getAnzahlAngebotserstellung: () => api.get(`${path}/angebotserstellung/anzahl`),
};

import _ from 'lodash';

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $hasGroup(groupId) {
          if (!this.$store.state.session.user) return false;
          if (this.$store.state.session.user.is_client_supervisor) return true;
          return _.some(this.$store.state.session.user.groups, { id: groupId });
        },
      },
    });
  },
};

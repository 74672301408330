/* eslint-disable camelcase */
import { DateTime } from 'luxon';
import _ from 'lodash';

export const werkzeugnummer = {
  key: 'werkzeugnummer',
  sortable: true,
  label: 'Werkzeugnummer',
  tdClass: 'py-1',
};

export const werkzeugtyp = {
  key: 'werkzeugtyp',
  sortable: true,
  label: 'Typ',
  tdClass: 'py-1',
  formatter: (value) => value?.name || '',
  sortKey: 'wt.name',
};

export const kunde = {
  key: 'kunde',
  sortable: true,
  label: 'Kunde',
  sortKey: 'kunde.nummer',
  tdClass: 'py-1',
  formatter: (value) => {
    if (value) {
      return `${value.nummer || ''} ${value.name || ''}`;
    }
    return '';
  },
};

export const werkzeugstatus = {
  key: 'werkzeugstatus',
  sortable: true,
  sortKey: 'ws.name',
  label: 'Status',
  tdClass: 'py-1',
  formatter: (value) => value?.name || '',
};

export const werkzeugstatus2 = {
  key: 'werkzeugstatus2',
  sortable: true,
  sortKey: 'ws_2.name',
  label: 'Status',
  tdClass: 'py-1',
  formatter: (value) => value?.name || '',
};

export const benoetigt_ab = {
  key: 'benoetigt_ab',
  sortable: true,
  label: 'Benötigt Ab',
  tdClass: 'py-1',
  formatter: (val) => {
    if (val) return DateTime.fromMillis(val).toFormat('dd.MM.yyyy');
    return '';
  },
};

export const zieldatum = {
  key: 'zieldatum',
  sortable: true,
  label: 'Zieldatum',
  tdClass: 'py-1',
  formatter: (val) => {
    if (val) return DateTime.fromMillis(val).toFormat('dd.MM.yyyy');
    return '';
  },
};

export const spaetestes_startdatum = {
  key: 'spaetestes_startdatum',
  sortable: true,
  label: 'Verspätetes Zieldatum',
  tdClass: 'py-1',
  formatter: (val) => {
    if (val) return DateTime.fromMillis(val).toFormat('dd.MM.yyyy');
    return '';
  },
};

export const letzter_historie_kommentar = {
  key: 'letzter_historie_kommentar',
  sortable: true,
  label: 'Letzter Kommentar',
  tdClass: 'py-1',
  formatter: (val) => {
    const s = _.truncate(val, { length: 150 });
    return s;
  },
};
export const artikelnummer = {
  key: 'artikelnummer',
  sortable: true,
  label: 'Artikelnummer',
  tdClass: 'py-1',
  sortKey: 'h_artikel.artikelnummer',
};

export const artikelbezeichnung = {
  key: 'artikelbezeichnung',
  sortable: true,
  label: 'Artikelbezeichnung',
  tdClass: 'py-1',
  sortKey: 'h_artikel.artikelbezeichnung',
};

export const kavitaeten = {
  key: 'kavitaeten',
  sortable: true,
  label: 'Kavitäten',
  tdClass: 'py-1',
};

export const herstelldatum = {
  key: 'herstelldatum',
  sortable: true,
  label: 'Herstelldatum',
  tdClass: 'py-1',
  formatter: (val) => {
    if (val) return DateTime.fromMillis(val).toFormat('dd.MM.yyyy');
    return '';
  },
};

export const erstintriebnahme = {
  key: 'erstintriebnahme',
  sortable: true,
  label: 'Erstinbetriebnahme',
  tdClass: 'py-1',
  formatter: (val) => {
    if (val) return DateTime.fromMillis(val).toFormat('dd.MM.yyyy');
    return '';
  },
};

export const stammlagerort = {
  key: 'stammlagerort',
  sortable: true,
  label: 'Stammlagerort',
  sortKey: 's_lagerort.name',
  tdClass: 'py-1',
  formatter: (value) => value?.name,
};

export default {
  stammliste: [
    werkzeugstatus,
  ],
  werkzeugbau: [
    werkzeugstatus2,
    benoetigt_ab,
    zieldatum,
    spaetestes_startdatum,
    letzter_historie_kommentar,
  ],
  possibleFields: [
    werkzeugstatus,
    werkzeugstatus2,
    artikelnummer,
    artikelbezeichnung,
    kavitaeten,
    herstelldatum,
    erstintriebnahme,
    stammlagerort,
  ],
};

import _ from 'lodash';

import privateLayout from '@l/Private.vue';
import eingangsrechnungRoutes from './eingangsrechnung';
import werkzeugverwaltungRoutes from './werkzeugverwaltung';
import messmittelverwaltungRoutes from './messmittelverwaltung';
import prozesskontrollkarteRoutes from './prozesskontrollkarte';
import abmusterungRoutes from './abmusterung';
import exportRoutes from './export';
import user from './user';
import settingsRoutes from './settings';

const routes = [
  {
    name: 'session-expired',
    path: '/session-expired',
    component: () => import('@v/Login.vue'),
    props: {
      sessionExpired: true,
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@v/Login.vue'),
  },
  {
    name: 'print-werkzeug-barcode',
    path: '/print/werkzeug/barcode/:id',
    component: () => import('@v/print/Werkzeugbarcode.vue'),
    props: true,
  },
  {
    name: 'print-messmittel-barcode',
    path: '/print/messmittel/barcode/:id',
    component: () => import('@v/print/Messmittelbarcode.vue'),
    props: true,
  },
  {
    path: '/',
    component: privateLayout,
    children: _.flatten([
      [
        {
          name: 'startseite',
          path: '',
          component: () => import('@v/Startseite.vue'),
        },
        {
          name: 'forbidden',
          path: '/forbidden',
          component: () => import('@v/errors/Forbidden.vue'),
        },
      ],
      eingangsrechnungRoutes,
      werkzeugverwaltungRoutes,
      messmittelverwaltungRoutes,
      prozesskontrollkarteRoutes,
      abmusterungRoutes,
      exportRoutes,
      user,
      settingsRoutes,
    ]),
  },
  {
    path: '/p/*',
    component: () => import('@/views/redirect/PrivateRedirect.vue'),
  },
  {
    path: '*',
    component: () => import('@v/errors/NotFound.vue'),
  },
];

export default routes;

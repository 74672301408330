/* eslint-disable no-console */
import EventBus, { SHOW_NEW_APP_VERSION } from '@/event-bus';
import store from '@st/index';
import { SESSION } from '@st/modules.type';
import { SET_WEBSOCKET_CLIENT_ID } from '@/store/session/mutations.type';
import { LOAD_USER } from '@/store/session/actions.type';
import websocketmessage from './message';

let websocket = null;

function getWebsocketUrl() {
  let protocol = 'wss:';
  let host = process.env.VUE_APP_SERVER_HOST;
  if (!host) {
    host = window.location.host;
  }
  if (window.location.protocol === 'http:') protocol = 'ws:';
  const websocketUrl = `${protocol}//${host}/.well-known/bx-websockets/com.batix.cmsplugins:websocket-message-broker/websocket`;
  return websocketUrl;
}

const handleMessage = async (event) => {
  const message = JSON.parse(event.data);
  switch (message.operation) {
    case websocketmessage.newAppVersion:
      console.info(`${new Date()} Neue App Version hochgeladen`);
      EventBus.$emit(SHOW_NEW_APP_VERSION);
      break;
    case websocketmessage.connectionOpened:
      store.commit(`${SESSION}/${SET_WEBSOCKET_CLIENT_ID}`, message.payload);
      break;
    case websocketmessage.request_user_reload:
      store.dispatch(LOAD_USER);
      break;
    default:
  }
};

function connect(userId, application) {
  websocket = new WebSocket(getWebsocketUrl());

  websocket.onerror = () => {
    console.error('[Websocket] Error');
    websocket.send(JSON.stringify({
      type: websocketmessage.registration, room: null, user: userId, application,
    }));
  };

  websocket.onopen = () => {
    console.success('[Websocket] Successfully connected');
    websocket.send(JSON.stringify({
      type: websocketmessage.registration, room: null, user: userId, application,
    }));
    setTimeout(() => {
      websocket.send(JSON.stringify({ type: websocketmessage.current_uri, uri: window.location.pathname + window.location.search + window.location.hash }));
    }, 500);
  };

  websocket.onclose = () => {
    console.info('[Websocket] Disconnected. Trying to Reconnect in 5 seconds');
    setTimeout(() => {
      connect(userId, application);
    }, 5000);
  };

  websocket.onmessage = async (event) => {
    await handleMessage(event);
  };

  websocket.join = (room) => {
    if (websocket.readyState === websocket.OPEN) {
      websocket.send(JSON.stringify({ type: websocketmessage.joinRoom, room }));
      console.info(`[Websocket] Joined Room ${room}`);
    }
  };

  websocket.leave = (room) => {
    if (websocket.readyState === websocket.OPEN) {
      websocket.send(JSON.stringify({ type: websocketmessage.leaveRoom, room }));
      console.info(`[Websocket] Leaved Room ${room}`);
    }
  };

  websocket.sendCurrentUri = (cu) => {
    if (websocket.readyState === websocket.OPEN) {
      websocket.send(JSON.stringify({ type: websocketmessage.current_uri, uri: cu }));
    }
  };

  return websocket;
}

export default {
  connect,
};

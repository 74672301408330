import axios from 'axios';
import _ from 'lodash';
import websocket from '@/services/websocket';
import usergroups from '@/config/user/groups';
import api from '../../services/api';
import router from '../../router';
import {
  CONNECT_WEBSOCKET, DISCONNECT_WEBSOCKET, JOIN_ROOM, LEAVE_ROOM, LOAD_USER, LOGIN, LOGOUT, SEND_CURRENT_URI,
} from './actions.type';
import { SET_USER, SET_WEBSOCKET, SET_WEBSOCKET_CLIENT_ID } from './mutations.type';
import { GET_USER_ID } from './getters.type';

function hasGroup(state, groupId) {
  if (!state.user) return false;
  return _.some(state.user.groups, { id: groupId });
}

export default {
  namespaced: true,
  state: {
    user: null,
    datenstand: null,
    websocket: null,
    websocketClientId: null,
  },
  mutations: {
    [SET_USER](state, val) {
      state.user = val;
    },
    [SET_WEBSOCKET](state, val) {
      state.websocket = val;
    },
    [SET_WEBSOCKET_CLIENT_ID](state, val) {
      state.websocketClientId = val;
    },
  },
  actions: {
    async [LOAD_USER](context) {
      context.commit(SET_USER, (await api.user.getCurrent()).data);
    },
    async [LOGIN](context, [username, password]) {
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);

      const loginUrl = '/login/';
      await axios.post(loginUrl, params);

      await context.dispatch(LOAD_USER);
    },
    async [LOGOUT](context) {
      await axios.post('/logout/', null, { withCredentials: true });
      context.commit(SET_USER, null);
      router.push({ name: 'login' });
    },
    [CONNECT_WEBSOCKET]({ state, commit }) {
      const ws = websocket.connect(state.user?.id, 'Frontend');
      commit(SET_WEBSOCKET, ws);
    },
    [DISCONNECT_WEBSOCKET]({ state }) {
      state.websocket?.close();
    },
    [JOIN_ROOM]({ state }, room) {
      state.websocket.join(room);
    },
    [LEAVE_ROOM]({ state }, room) {
      state.websocket.leave(room);
    },
    [SEND_CURRENT_URI]({ state }) {
      state.websocket?.sendCurrentUri(window.location.pathname + window.location.search + window.location.hash);
    },
  },
  modules: {
  },
  getters: {
    username: (state) => state.user?.full_name,
    [GET_USER_ID]: (state) => state.user?.id,
    isAdmin: (state) => state.user?.groups?.filter((g) => g.id === '17B1225AB83')?.length > 0,
    isLoggedIn: (state) => state.user !== null,
    isSupervisor: (state) => {
      if (!state.user) return false;
      return state.user?.is_batix_supervisor
        || state.user?.is_client_supervisor
        || state.user?.is_supervisor
        || state.user?.is_system_supervisor;
    },
    canBerechtigungenVergeben: (state, getters) => hasGroup(state, usergroups.berechtigungen_verwalten) || getters.isSupervisor,
  },
};

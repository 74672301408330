import numeral from 'numeral';

export function formatPrice(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return '';
  return numeral(value).format('0,0.00');
}

export function formatPriceEuro(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return '';
  return `${numeral(value).format('0,0.00')} €`;
}

export function formatOnePrecision(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return '';
  return numeral(value).format('0,0.0');
}

export function formatThreePrecision(value) {
  if (value == null || Number.isNaN(value) || !Number.isFinite(value)) return '';
  return numeral(value).format('0,0.[000]');
}

export function formatDecimal(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return null;
  return numeral(value).format('0,0.[#]');
}

export function formatPercent(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return '';
  return numeral(value).format('0.[00] %');
}

export function formatThousendSeparator(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return '';
  return numeral(value).format('0,0.0');
}

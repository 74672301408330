import { AbilityBuilder } from '@casl/ability';
import { PROZESSKONTROLLKARTE } from '@/constants/security/modules.type';
import {
  ACCESS, EDIT_ACTUAL, EDIT_PCK, READ_MANUFACTURING_ORDERS,
  READ_PCK, EXPORT, READ_COMPLETED, ASSIGN_PCK,
} from '@/constants/security/prozesskontrollkarte/ability.type';
import axios from 'axios';

import _ from 'lodash';

function canAccessProzesskontrollkarte(abilities) {
  const a = abilities[PROZESSKONTROLLKARTE];
  return a[READ_PCK] || a[EDIT_PCK] || a[EXPORT] || a[EDIT_ACTUAL] || a[READ_COMPLETED] || a[READ_MANUFACTURING_ORDERS] || a[ASSIGN_PCK];
}

export function defineAbilities(user) {
  const { rules, can } = new AbilityBuilder();
  _.toPairs(user.abilities).forEach(([module, abilities]) => {
    _.toPairs(abilities).forEach(([ability, hasAbility]) => {
      if (hasAbility) {
        can([ability], module);
      }
    });
  });

  const { abilities } = user;

  if (canAccessProzesskontrollkarte(abilities)) {
    can(ACCESS, PROZESSKONTROLLKARTE);
  }

  if (abilities[PROZESSKONTROLLKARTE][ASSIGN_PCK]) {
    can(READ_MANUFACTURING_ORDERS, PROZESSKONTROLLKARTE);
  }

  if (abilities[PROZESSKONTROLLKARTE][EDIT_PCK]) {
    can(READ_PCK, PROZESSKONTROLLKARTE);
  }

  if (abilities.casl.manage_users || abilities.casl.manage_usergroups || abilities.casl.manage_abilities) {
    can('manage', 'casl');
  }

  if (abilities.presentation_display.manage) {
    can('manage', 'settings');
  }

  return rules;
}

export async function loadAbilities() {
  const response = await axios.get('/bx-casl-api/v1/user/ability', { withCredentials: true });
  return response.data;
}

<template>
  <b-modal ref="modal" title="Neue Version der Anwendung" centered ok-title="Jetzt aktualisieren"
    cancel-title="schließen und später aktualisieren" header-bg-variant="cosmos" header-text-variant="white"
    @hide="onHide" @ok="onOk">
    <div class="text-center">
      Es wurde eine neue Version der Anwendung veröffentlich. Bitte
      Aktualisieren Sie mitn einem Klick auf 'Jetzt aktualisieren'
    </div>
    <!-- <div class="font-weight-bold text-center font-size-xxl">
      {{ counter }} sek.
    </div> -->
    <div class="text-center">
      Wenn Sie noch ungespeicherte Änderungen haben, schließen Sie bitte dieses
      Fenster und laden die Anwendung bei Bedarf selbst neu.
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'NewAppVersionModal',
  data() {
    return {
      counter: 30,
      interval: null,
    };
  },
  methods: {
    show() {
      this.counter = 30;

      this.$refs.modal.show();

      /*
      var self = this;

      this.interval = setInterval(function () {
        if (self.counter === 0) location.reload();
        else self.counter = self.counter - 1;
      }, 1000);
      */
    },
    hide() {
      this.$emit('hide');
      this.$refs.modal.hide();
    },
    onHide() {
      // clearInterval(this.interval);
    },
    onOk() {
      window.location.reload();
    },
  },
};
</script>

<style></style>

import api from '@a/index';

import { LOAD_HISTORIETYPEN } from './actions.type';
import { SET_HISTORIETYPEN, SET_HISTORIETYPEN_LOADED } from './mutations.type';

export default {
  namespaced: true,
  state: {
    historietypen: [],
    _historietypenloaded: false,
  },
  mutations: {
    [SET_HISTORIETYPEN](state, val) {
      state.historietypen = val;
    },
    [SET_HISTORIETYPEN_LOADED](state, val) {
      state._historietypenloaded = val;
    },
  },
  actions: {
    async [LOAD_HISTORIETYPEN]({ commit, state }) {
      if (!state._historietypenloaded) {
        const response = await api.v1.werkzeug.historie.typ.get();
        commit(SET_HISTORIETYPEN, response.data);
        commit(SET_HISTORIETYPEN_LOADED, true);
      }
    },
  },
  modules: {
  },
  getters: {

  },
};

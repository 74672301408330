import { LISTE_PER_PAGE, ADDITIONAL_FIELDS } from './key.type';

function getAdditionalFieldsKey(userId, routeName) {
  return `${ADDITIONAL_FIELDS}_${userId}_${routeName}`;
}

export default {
  setPerPage: (prefix, value) => {
    localStorage.setItem(LISTE_PER_PAGE + prefix, value);
  },
  getPerPage: (prefix) => {
    const val = localStorage.getItem(LISTE_PER_PAGE + prefix);
    if (val) return parseInt(val, 10);
    return null;
  },
  setAdditionalFields(userId, routeName, value) {
    localStorage.setItem(getAdditionalFieldsKey(userId, routeName), JSON.stringify(value));
  },
  getAdditionalFieldsKey(userId, routeName) {
    const val = localStorage.getItem(getAdditionalFieldsKey(userId, routeName));
    if (val) return JSON.parse(val);
    return [];
  },
};

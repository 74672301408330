import axios from 'axios';
import router from '@/router';

// eslint-disable-next-line import/no-cycle
import store from '@st/index';
import { SESSION } from '@st/modules.type';
import { SET_USER } from '@st/session/mutations.type';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  // Do something before request is sent

  const { websocketClientId } = store.state[SESSION];
  // eslint-disable-next-line no-param-reassign
  config.headers['x-websocket-client-id'] = websocketClientId;
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, (error) => {
  // Benutzer ist niocht authentifiziert
  if (error.response?.status === 401) {
    store.commit(`${SESSION}/${SET_USER}`, null);
    const url = `${window.location.pathname}${window.location.search}`.substring(process.env.VUE_APP_BASE_URL.length);
    const location = router.resolve(`/${url}`);
    if (location.resolved) {
      router.push({ name: 'login', query: { redir: location.resolved.fullPath } });
    } else {
      router.push({ name: 'login' });
    }
  }
  return Promise.reject(error);
});

export default api;

import api from '@/services/api';
import Vue from 'vue';
import Vuex from 'vuex';
import { LOAD_ABTEILUNGEN, LOAD_DOKUMENTTYPEN, LOAD_USERS } from './actions.type';
import {
  SET_ABTEILUNGEN, SET_CURRENT_TIMESTAMP, SET_DOKUMENTTYPEN, SET_USERS,
} from './mutations.type';

import session from './session';
import werkzeugverwaltung from './werkzeugverwaltung';
import messmittelverwaltung from './messmittelverwaltung';
import prozesskontrollkarte from './prozesskontrollkarte';
import abmusterung from './abmusterung';
import {
  SESSION, MESSMITTELVERWALTUNG, WERKZEUGVERWALTUNG, PROZESSKONTROLLKARTE, ABMUSTERUNG,
} from './modules.type';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    abteilungen: [],
    user: [],
    dokumenttypen: [],
    current_timestamp: null,
  },
  mutations: {
    [SET_ABTEILUNGEN](state, val) {
      state.abteilungen = val;
    },
    [SET_DOKUMENTTYPEN](state, val) {
      state.dokumenttypen = val;
    },
    [SET_USERS](state, val) {
      state.user = val;
    },
    [SET_CURRENT_TIMESTAMP](state, val) {
      state.current_timestamp = val;
    },
  },
  actions: {
    async [LOAD_ABTEILUNGEN](context) {
      const response = await api.abteilung.get();
      context.commit(SET_ABTEILUNGEN, response.data);
    },
    async [LOAD_DOKUMENTTYPEN](context) {
      const response = await api.v1.dokument.typ.get();
      context.commit(SET_DOKUMENTTYPEN, response.data);
    },
    async [LOAD_USERS](context) {
      const response = await api.user.getAll();
      context.commit(SET_USERS, response.data);
    },
  },
  modules: {
    [SESSION]: session,
    [WERKZEUGVERWALTUNG]: werkzeugverwaltung,
    [MESSMITTELVERWALTUNG]: messmittelverwaltung,
    [PROZESSKONTROLLKARTE]: prozesskontrollkarte,
    [ABMUSTERUNG]: abmusterung,
  },
});

import Eingangsrechnung from '@/layouts/Eingangsrechnungen.vue';
import EingangsrechnungListe from '@v/eingangsrechnung/Liste.vue';
import EingangsrechnungDetail from '@v/eingangsrechnung/Detail.vue';
import Rechnungseingangsbuch from '@v/eingangsrechnung/Rechnungseingangsbuch.vue';
import listtype from '@/config/eingangsrechnung/listtypes';

export default [
  {
    path: 'eingangsrechnungen',
    component: Eingangsrechnung,
    children: [
      {
        path: '',
        name: 'eingangsrechnungen',
        component: () => import('@v/eingangsrechnung/EingangsrechnungenStartseite.vue'),
      },
      {
        path: 'liste/unzugeordnet',
        name: 'eingangsrechnung.liste.unzugeordnet',
        component: EingangsrechnungListe,
        props: {
          type: {
            id: listtype.unzugeordnet,
            title: 'Unzugeordnete Eingangsrechnungen',
            pagination: false,
          },
        },
      },
      {
        path: 'liste/abteilung/:abteilung',
        name: 'eingangsrechnung.liste.abteilung',
        component: EingangsrechnungListe,
        props: (route) => ({
          type: {
            id: listtype.abteilung,
            value: route.params.abteilung,
            pagination: false,
          },
        }),
      },
      {
        path: 'liste/freigegeben',
        name: 'eingangsrechnung.liste.freigegeben',
        component: EingangsrechnungListe,
        props: {
          type: {
            id: listtype.freigegeben,
            title: 'Freigegebene Eingangsrechnungen',
            pagination: false,
            showExport: true,
          },
        },
      },
      {
        path: 'liste/archiv',
        name: 'eingangsrechnung.liste.archiv',
        component: EingangsrechnungListe,
        props: {
          type: {
            id: listtype.archiv,
            title: 'Archiv',
            pagination: true,
          },
        },
      },
      {
        path: 'liste/papierkorb',
        name: 'eingangsrechnung.liste.papierkorb',
        component: EingangsrechnungListe,
        props: {
          type: {
            id: listtype.papierkorb,
            title: 'Papierkorb',
            pagination: true,
          },
        },
      },
      {
        path: 'liste/abgelehnt',
        name: 'eingangsrechnung.liste.abgelehnt',
        component: EingangsrechnungListe,
        props: {
          type: {
            id: listtype.abgelehnt,
            title: 'Abgelehnte Eingangsrechungen',
            pagination: true,
          },
        },
      },
      {
        path: 'detail/:id',
        name: 'eingangsrechnung.detail',
        component: EingangsrechnungDetail,
        props: true,
      },
      {
        path: 'rechnungseingangsbuch',
        name: 'rechnungseingangsbuch',
        component: Rechnungseingangsbuch,
        props: true,
      },
    ],
  },
];

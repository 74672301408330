export const LOAD_WERKZEUGSTATI = 'load_werkzeugstati';
export const LOAD_WERKZEUGSUBSTATI = 'load_werkzeugsubstati';
export const LOAD_WERKZEUGTYPEN = 'load_werkzeugtypen';
export const LOAD_WERKZEUGGRUPPEN = 'load_werkzeuggruppen';
export const LOAD_WERKZEUGAUSFUEHRUNGEN = 'load_werkzeugausfuehrungen';
export const LOAD_LAGERORTE = 'load_lagerorte';
export const LOAD_STANDORTE = 'load_standorte';
export const LOAD_KATEGORIEN = 'load_kategorien';
export const ADD_LAGERORT = 'add_lagerort';

export const LOAD_ANZAHL_WERKZEUGBAU = 'load_anzahlwerkzeugbau';
export const LOAD_ANZAHL_ANZAHL_KUERZLICH_ABGESCHLOSSEN = 'load_anzahl_kuerzlich_abgeschlossen';
export const LOAD_ANZAHL_ANZAHL_ANGEBOTSERSTELLUNG = 'load_anzahl_angebotserstellung';

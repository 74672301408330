import VueDateTimePicker from '@bx/vue-date-time-picker';
import VueSelect from 'vue-select';
import { Can } from '@casl/vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone';
import draggable from 'vuedraggable';
import VueTypeaheadBootstrap from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
import Navbar from './Navbar.vue';

/**
 * Setup Global accessible Components for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
// eslint-disable-next-line import/prefer-default-export
export function setupGlobalComponents(instance) {
  instance.component('navbar', Navbar);

  instance.component('vue-date-time-picker', VueDateTimePicker);

  instance.component('v-select', VueSelect);

  instance.component('Can', Can);

  instance.component('v-dropzone', vue2Dropzone);

  instance.component('draggable', draggable);

  instance.component('v-typeahead', VueTypeaheadBootstrap);
}

import api from '@a/config';

const path = 'v1/werkzeug/werkzeugbau';

export default {
  get: (page, size, sort = null, desc = false, werkzeug = null, suche = null, auch_abgeschlossene = false) => api.get(path, {
    params: {
      page, size, sort, desc, suche, werkzeug, auch_abgeschlossene,
    },
  }),
  getFromWerkzeug: (werkzeug) => api.get(path, { params: { werkzeug } }),
  getAnzahl: () => api.get(`${path}/anzahl`),
  getById: (id) => api.get(`${path}/${id}`),
  create: (werkzeug, benoetigtAb) => api.post(path, { werkzeug, benoetigt_ab: benoetigtAb }),
  getHistorie: (id, page, size, sort = null, desc = false) => api.get(`${path}/${id}/historie`, {
    params: {
      page, size, sort, desc,
    },
  }),
  addHistorie: (id, formData) => {
    api.post(`${path}/${id}/historie`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getDokumente: (id, page, size, sort = null, desc = false) => api.get(`${path}/${id}/dokument`, {
    params: {
      page, size, sort, desc,
    },
  }),
  addDocument: (id, formData) => {
    api.post(`${path}/${id}/dokument`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  zieldatumEintragen: (id, zieldatum) => api.patch(`${path}/${id}`, { operation: 'zieldatum_eingetragen', werkzeugbau: { zieldatum } }),
  spaetestesStartdatumEintragen: (id, spaetestesStartdatum) => api.patch(`${path}/${id}`, { operation: 'spaetestes_startdatum_eingetragen', werkzeugbau: { spaetestes_startdatu: spaetestesStartdatum } }),
  fertiggestelltEintragen: (id, datumFertigstellung) => api.patch(`${path}/${id}`, { operation: 'fertiggestellt', werkzeugbau: { datum_fertigstellung: datumFertigstellung } }),
  nacharbeitEintragen: (id) => api.patch(`${path}/${id}`, { operation: 'nacharbeit', werkzeugbau: {} }),

};

import api from '@a/config';

const path = 'v1/werkzeug/eingangspruefung';

export default {
  get: (page, size, sort = null, desc = false, suche = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  create: (eingangspruefung) => api.post(path, eingangspruefung),
  addDokumente: (id, formData) => api.post(`${path}/${id}/dokument`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
};

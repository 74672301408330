import api from '@a/config';

const path = 'v1/prozesskontrollkarte/admin/prozesskontrollkarte';

export default {
  get: (page, size, sort = null, desc = false, suche = null, status = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, status,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  create: (prozesskontrollkarte) => api.post(path, prozesskontrollkarte),
  update: (id, prozesskontrollkarte) => api.put(`${path}/${id}`, prozesskontrollkarte),
  delete: (id) => api.delete(`${path}/${id}`),
  finalisieren: (id) => api.patch(`${path}/${id}/finalisieren`),
  archivieren: (id) => api.patch(`${path}/${id}/archivieren`),
  getGueltigkeitsbereichKollision: (id, gueltigAbParam, gueltigBisParam, artikelnummer, zeichnungsnummer, zeichnungsindex, abteilung, position) => api.get(`${path}/${id}/gueltigkeitsbereich/kollision`, {
    params: {
      gueltig_ab: gueltigAbParam, gueltig_bis: gueltigBisParam, artikelnummer, zeichnungsnummer, zeichnungsindex, abteilung, position,
    },
  }),
};

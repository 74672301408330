import { ABGESCHLOSSEN } from '@/constants/werkzeugverwaltung/abmusterung/status';

export default [
  {
    path: 'abmusterung',
    component: () => import('@/layouts/abmusterung/AbmusterungLayout.vue'),
    children: [
      {
        name: 'abmusterung',
        path: '',
        component: () => import('@/views/abmusterung/AbmusterungStartseite.vue'),
      },
      {
        path: 'list',
        name: 'abmusterung.list',
        component: () => import('@v/abmusterung/AbmusterungListe.vue'),
        props: {
          archiv: false,
          abteilungId: null,
          statusId: null,
        },
      },
      {
        path: 'list/archiv',
        name: 'abmusterung.list.archiv',
        component: () => import('@v/abmusterung/AbmusterungListe.vue'),
        props: {
          archiv: true,
          abteilungId: null,
          statusId: ABGESCHLOSSEN,
        },
      },
      {
        path: 'list/offen',
        name: 'abmusterung.list.offen',
        component: () => import('@v/abmusterung/AbmusterungListe.vue'),
        props: {
          archiv: false,
          offen: true,
          abteilungId: null,
          notStatusId: ABGESCHLOSSEN,
        },
      },
      {
        path: 'list/abteilung/:abteilung',
        name: 'abmusterung.list.abteilung',
        component: () => import('@v/abmusterung/AbmusterungListe.vue'),
        props: (route) => ({
          archiv: false,
          abteilungId: route.params.abteilung,
        }),
      },
      {
        path: 'list/abteilung/:abteilung/status/:status',
        name: 'abmusterung.list.abteilung.status',
        component: () => import('@v/abmusterung/AbmusterungListe.vue'),
        props: (route) => ({
          archiv: false,
          abteilungId: route.params.abteilung,
          statusId: route.params.status,
        }),
      },
      {
        path: 'neu/:abteilung',
        name: 'abmusterung.neu.abteilung',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          edit: true,
          abteilungId: route.params.abteilung,
        }),
      },
      {
        path: 'neu/:abteilung/:tab',
        name: 'abmusterung.neu.abteilung.tab',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          edit: true,
          abteilungId: route.params.abteilung,
          tab: route.params.tab,
        }),
      },
      {
        path: ':id',
        name: 'abmusterung.id',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: false,
          tab: null,
        }),
      },
      {
        path: ':id/edit',
        name: 'abmusterung.id.edit',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
          tab: null,
        }),
      },
      {
        path: ':id/:tab',
        name: 'abmusterung.id.tab',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: false,
          tab: route.params.tab,
        }),
      },

      {
        path: ':id/edit/:tab',
        name: 'abmusterung.id.edit.tab',
        component: () => import('@v/abmusterung/Abmusterung.vue'),
        props: (route) => ({
          id: route.params.id,
          edit: true,
          tab: route.params.tab,
        }),
      },
    ],
  },
];

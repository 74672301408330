export const SET_WERKZEUGSTATI = 'set_werkzeugstati';
export const SET_WERKZEUGSTATI_LOADED = 'set_werkzeugstati_loaded';

export const SET_WERKZEUGSUBSTATI = 'set_werkzeugsubstati';
export const SET_WERKZEUGSUBSTATI_LOADED = 'set_werkzeugsubstati_loaded';

export const SET_WERKZEUGTYPEN = 'set_werkzeugtypen';
export const SET_WERKZEUGTYPEN_LOADED = 'set_werkzeugtypen_loaded';

export const SET_WERKZEUGGRUPPEN = 'set_werkzeuggruppen';
export const SET_WERKZEUGGRUPPEN_LOADED = 'set_werkzeugruppen_loaded';

export const SET_WERKZEUGAUSFUEHRUNGEN = 'set_werkzeugausfuehrungen';
export const SET_WERKZEUGAUSFUEHRUNGEN_LOADED = 'set_werkzeugausfuehrungen_loaded';

export const SET_LAGERORTE = 'set_lagerorte';
export const SET_LAGERORTE_LOADED = 'set_lagerorte_loaded';

export const SET_STANDORTE = 'set_standorte';
export const SET_STANDORTE_LOADED = 'set_standorte_loaded';

export const SET_ANZAHL_WERKZEUGBAU = 'set_anzahl_werkzeugbau';
export const SET_ANZAHL_KUERZLICH_ABGESCHLOSSEN = 'set_anzahl_kuerzlich_abgeschlossen';
export const SET_ANZAHL_ANGEBOTSERSTELLUNG = 'set_anzahl_angebotserstellung';

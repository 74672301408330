import api from '@a/config';
import abteilungApi from './abteilung';
import grundApi from './grund';

const path = 'v1/abmusterung';

export default {
  get: (page, size, sort = null, desc = false, suche = null, abteilung = null, status = null, not_status = null, grund = null) => api.get(path, {
    params: {
      page, size, sort, desc, suche, abteilung, grund, status, not_status,
    },
  }),
  getById: (id) => api.get(`${path}/${id}`),
  create: (abmusterung) => api.post(path, abmusterung),
  abteilung: abteilungApi,
  grund: grundApi,
  updateEntwicklung: (id, abmusterung) => api.patch(`${path}/${id}/entwicklung`, abmusterung),
  entwicklungAbschliessen: (id) => api.patch(`${path}/${id}/entwicklung/abschliessen`),
  updateAbfragen: (id, abmusterung) => api.patch(`${path}/${id}/abfragen`, abmusterung),
  abfragenAbschliessen: (id) => api.patch(`${path}/${id}/abfragen/abschliessen`),
  abfragenQsAbschliessen: (id) => api.patch(`${path}/${id}/abfragen/qs/abschliessen`),
  updateDurchfuehrung: (id, abmusterung) => api.patch(`${path}/${id}/durchfuehrung`, abmusterung),
  updateDokumentation: (id, abmusterung) => api.patch(`${path}/${id}/dokumentation`, abmusterung),
  dokumentationAbschliessen: (id) => api.patch(`${path}/${id}/dokumentation/abschliessen`),
  updateUnterschriften: (id, abmusterung) => api.patch(`${path}/${id}/unterschriften`, abmusterung),
  abschliessen: (id) => api.patch(`${path}/${id}/abschliessen`),
  getStatistik: () => api.get(`${path}/statistik`),
  folgeabmusterungErstellen: (id, abteilung) => api.post(`${path}/${id}/folgeabmusterung/abteilung/${abteilung}`),
  getFolgeabmusterungen: (id, page, size, sort = null, desc = false) => api.get(path, {
    params: {
      entstanden_aus_abmusterung: id, page, size, sort, desc,
    },
  }),
  pdfErzeugen: (id) => api.post(`${path}/${id}/pdf`),
  delete: (id) => api.delete(`${path}/${id}`),
};

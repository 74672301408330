import EventBus, { SHOW_FILE } from '@/event-bus';

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $openFile(file, gallery = []) {
          EventBus.$emit(SHOW_FILE, { file, gallery });
        },
      },
    });
  },
};

import api from '@a/index';

import {
  LOAD_MESSMITTELSTATI,
  LOAD_MESSMITTELTYPEN,
  LOAD_MESSMITTELGRUPPEN,
  LOAD_KATEGORIEN,
  LOAD_STANDORTE,
  LOAD_ANZAHL_MESSMITTEL,
  LOAD_ANZAHL_MESSMITTEL_EXTERN,
  LOAD_MESSVERFAHREN,
  LOAD_MESSBEDINGUNGEN,
  LOAD_PREUFVORSCHRIFTEN,
  LOAD_MESSMITTEL_KATEGORIEN,
} from './actions.type';
import {
  SET_MESSMITTELSTATI,
  SET_MESSMITTELSTATI_LOADED,
  SET_MESSMITTELTYPEN,
  ADD_MESSMITTELTYP,
  SET_MESSMITTELTYPEN_LOADED,
  SET_MESSMITTELGRUPPEN,
  SET_MESSMITTELGRUPPEN_LOADED,
  SET_STANDORTE,
  SET_STANDORTE_LOADED,
  SET_ANZAHL_MESSMITTEL,
  SET_ANZAHL_MESSMITTEL_EXTERN,
  SET_MESSVERFAHREN_LOADED,
  SET_MESSVERFAHREN,
  SET_MESSBEDINGUNGEN,
  SET_MESSBEDINGUNGEN_LOADED,
  SET_PREUFVORSCHRIFTEN,
  SET_PREUFVORSCHRIFTEN_LOADED,
  SET_MESSMITTEL_KATEGORIEN,
  SET_MESSMITTEL_KATEGORIEN_LOADED,
} from './mutations.type';

import historie from './historie';

export default {
  namespaced: true,
  state: {
    messmittelstati: [],
    _messmittelstati_loaded: false,
    messmitteltypen: [],
    _messmitteltypen_loaded: false,
    messmittelgruppen: [],
    _messmittelgruppen_loaded: false,
    messmittelausfuehrungen: [],
    _messmittelausfuehrungen_loaded: false,
    lagerorte: [],
    _lagerorte_loaded: false,
    standorte: [],
    _standorte_loaded: false,
    messverfahren: [],
    _messverfahren_loaded: false,
    messbedingungen: [],
    _messbedingungen_loaded: false,
    pruefvorschriften: [],
    _pruefvorschriften_loaded: false,
    messmittel_kategorien: [],
    _messmittel_kategorien_loaded: false,
    anzahl_messmittel: null,
    anzahl_messmittel_extern: null,
  },
  mutations: {
    [SET_MESSMITTELSTATI](state, val) {
      state.messmittelstati = val;
    },
    [SET_MESSMITTELSTATI_LOADED](state, val) {
      state._messmittelstati_loaded = val;
    },
    [SET_MESSMITTELTYPEN](state, val) {
      state.messmitteltypen = val;
    },
    [ADD_MESSMITTELTYP](state, val) {
      state.messmitteltypen.push(val);
    },
    [SET_MESSMITTELTYPEN_LOADED](state, val) {
      state._messmitteltypen_loaded = val;
    },
    [SET_MESSMITTELGRUPPEN](state, val) {
      state.messmittelgruppen = val;
    },
    [SET_MESSMITTELGRUPPEN_LOADED](state, val) {
      state._messmittelgruppen_loaded = val;
    },
    [SET_STANDORTE](state, val) {
      state.standorte = val;
    },
    [SET_STANDORTE_LOADED](state, val) {
      state._standorte_loaded = val;
    },
    [SET_MESSVERFAHREN](state, val) {
      state.messverfahren = val;
    },
    [SET_MESSVERFAHREN_LOADED](state, val) {
      state._messverfahren_loaded = val;
    },
    [SET_MESSBEDINGUNGEN](state, val) {
      state.messbedingungen = val;
    },
    [SET_MESSBEDINGUNGEN_LOADED](state, val) {
      state._messbedingungen_loaded = val;
    },
    [SET_PREUFVORSCHRIFTEN](state, val) {
      state.pruefvorschriften = val;
    },
    [SET_PREUFVORSCHRIFTEN_LOADED](state, val) {
      state._pruefvorschriften_loaded = val;
    },
    [SET_MESSMITTEL_KATEGORIEN](state, val) {
      state.messmittel_kategorien = val;
    },
    [SET_MESSMITTEL_KATEGORIEN_LOADED](state, val) {
      state._messmittel_kategorien_loaded = val;
    },
    [SET_ANZAHL_MESSMITTEL](state, val) {
      state.anzahl_messmittel = val;
    },
    [SET_ANZAHL_MESSMITTEL_EXTERN](state, val) {
      state.anzahl_messmittel_extern = val;
    },
  },
  actions: {
    async [LOAD_MESSMITTELSTATI]({ commit, state }, force = false) {
      if (state._messmittelstati_loaded && !force) {
        return state.messmittelstati;
      }
      const response = await api.v1.messmittel.status.get();
      commit(SET_MESSMITTELSTATI, response.data);
      commit(SET_MESSMITTELSTATI_LOADED, true);
      return response.data;
    },
    async [LOAD_MESSMITTELTYPEN]({ commit, state }, force = false) {
      if (state._messmitteltypen_loaded && !force) {
        return state.messmitteltypen;
      }
      const response = await api.v1.messmittel.typ.get();
      commit(SET_MESSMITTELTYPEN, response.data);
      commit(SET_MESSMITTELTYPEN_LOADED, true);
      return response.data;
    },
    async [LOAD_MESSMITTELGRUPPEN]({ commit, state }, force = false) {
      if (state._messmittelgruppen_loaded && !force) {
        return state.messmittelgruppen;
      }
      const response = await api.v1.messmittel.gruppe.get();
      commit(SET_MESSMITTELGRUPPEN, response.data);
      commit(SET_MESSMITTELGRUPPEN_LOADED, true);
      return response.data;
    },
    async [LOAD_STANDORTE]({ commit, state }, force = false) {
      if (state._standorte_loaded && !force) {
        return state.standorte;
      }
      const response = await api.v1.messmittel.standort.get();
      commit(SET_STANDORTE, response.data);
      commit(SET_STANDORTE_LOADED, true);
      return response.data;
    },
    async [LOAD_MESSVERFAHREN]({ commit, state }, force = false) {
      if (state._messverfahren_loaded && !force) {
        return state.messverfahren;
      }
      const response = await api.v1.messmittel.messverfahren.get();
      commit(SET_MESSVERFAHREN, response.data);
      commit(SET_MESSVERFAHREN_LOADED, true);
      return response.data;
    },
    async [LOAD_MESSBEDINGUNGEN]({ commit, state }, force = false) {
      if (state._messbedingungen_loaded && !force) {
        return state.messbedingungen;
      }
      const response = await api.v1.messmittel.messbedingung.get();
      commit(SET_MESSBEDINGUNGEN, response.data);
      commit(SET_MESSBEDINGUNGEN_LOADED, true);
      return response.data;
    },
    async [LOAD_PREUFVORSCHRIFTEN]({ commit, state }, force = false) {
      if (state._pruefvorschriften_loaded && !force) {
        return state.pruefvorschriften;
      }
      const response = await api.v1.messmittel.pruefvorschrift.get();
      commit(SET_PREUFVORSCHRIFTEN, response.data);
      commit(SET_PREUFVORSCHRIFTEN_LOADED, true);
      return response.data;
    },
    async [LOAD_MESSMITTEL_KATEGORIEN]({ commit, state }, force = false) {
      if (state._messmittel_kategorien_loaded && !force) {
        return state.messmittel_kategorien;
      }
      const response = await api.v1.messmittel.kategorie.get();
      commit(SET_MESSMITTEL_KATEGORIEN, response.data);
      commit(SET_MESSMITTEL_KATEGORIEN_LOADED, true);
      return response.data;
    },
    async [LOAD_ANZAHL_MESSMITTEL]({ commit }) {
      const response = await api.v1.messmittel.getAnzahl();
      commit(SET_ANZAHL_MESSMITTEL, response.data);
      return response.data;
    },
    async [LOAD_ANZAHL_MESSMITTEL_EXTERN]({ commit }) {
      const response = await api.v1.messmittel.getAnzahl(true);
      commit(SET_ANZAHL_MESSMITTEL_EXTERN, response.data);
      return response.data;
    },
    async [LOAD_KATEGORIEN]({ dispatch }) {
      await Promise.all([
        dispatch(LOAD_MESSMITTELSTATI),
        dispatch(LOAD_MESSMITTELTYPEN),
        dispatch(LOAD_MESSMITTELGRUPPEN),
        dispatch(LOAD_STANDORTE),
        dispatch(LOAD_MESSVERFAHREN),
        dispatch(LOAD_MESSBEDINGUNGEN),
        dispatch(LOAD_PREUFVORSCHRIFTEN),
        dispatch(LOAD_MESSMITTEL_KATEGORIEN),
      ]);
    },
  },
  modules: {
    historie,
  },
  getters: {
  },
};
